import React, {Component} from 'react'
import HereMap from '../mapComponents/HereMap'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import { withTracker } from '../../config/GoogleAnalytics';
import { firestoreConnect, withFirebase } from 'react-redux-firebase'
import { compose } from 'redux'
import { saveDistances } from '../../redux/actions/businessActions'
import { filtersVisible } from '../../redux/actions/navActions'
import update from 'immutability-helper'
import HerePlatform from '../mapComponents/HerePlatformHOC'
// import { storeLogoURL } from '../../redux/actions/dbActions'
import List from '../listComponents/List'
import FilterSearchOptions from '../nav/FilterSearchOptions'
import ToggleView from '../nav/ToggleView';
import industryData from '../../data/industries.json'
import moment from 'moment';

class HomeScreen extends Component {
  state = {
      businesses: [],
      filteredBusinesses: [],
      searchTerm: null,
      activeFilters: [],
      distanceRange: 0,
      favMode: false
  }

  updateBusinesses = (data) => {
    this.setState({businesses: data});
  }

  componentDidMount() {
    if(this.props.businesses){
      this.setState({businesses: this.props.businesses}, () => {
        // this.saveLogoURLs();
        this.applyFilters();
      });
    }

    if(this.props.userPosition !== undefined){
      this.getDistances();
    }

    if(this.props.favList){
      this.setState({favMode: true})
    }
  }

  componentDidUpdate (prevProps) {
    if((!prevProps.businesses || prevProps.businesses !== this.props.businesses) && this.props.businesses){ 
      this.setState({businesses: this.props.businesses}, () => {
          // this.saveLogoURLs();
          this.applyFilters();

          if(this.props.userPosition !== undefined){
            this.getDistances();
          }
      });
    }

    if(!prevProps.favList && this.props.favList){
      this.setState({favMode: true})
    } else if (prevProps.favList && !this.props.favList){
      this.setState({favMode: false})
    }

    if(this.props.userPosition && this.props.userPosition !== undefined && prevProps.userPosition !== this.props.userPosition){
      this.getDistances();
    }
  }

  // saveLogoURLs = () => {
  //   let self = this;
  //   console.log(this.state.businesses)
  //   // let newState = this.state;

  //   for(const [index, business] of this.state.businesses.entries()){
  //     if(!business.logoURL && business.logo){
  //       let storage = self.props.firebase.storage();
  //       var storageRef = storage.ref(`userUploads/${business.id}/${business.logo}`);

  //       storageRef.getDownloadURL().then((url) => {
  //         let logoObj = {[business.id]: url}
  //         this.saveNewState(logoObj);
  //       }).catch(function(error) {
  //           console.log(error)
  //       });
  //     }
  //   }
  // }

  // saveNewState = (logoObj) => {
  //   // console.log(logoObj);
  //   let newState = update(this.state, {
  //     logos: {$merge: logoObj}
  //   });

  //   this.setState(newState)
  // }

  getDistances = () => {
    let distances = {};

    for (const business of this.state.businesses){
      if (business.address && business.address !== 'online'){
        let addressObj = JSON.parse(business.address)

        let userPos = new this.props.here.geo.Point(this.props.userPosition.coords.latitude, this.props.userPosition.coords.longitude)
        let comparePoint = new this.props.here.geo.Point(addressObj.location.displayPosition.latitude, addressObj.location.displayPosition.longitude)

        let distance = Math.round(userPos.distance(comparePoint));
        
        distances[business.id] = distance;
      }
    }

    this.setState({distances: distances}, () => {
      this.props.saveDistances(this.state.distances)
    })
  }

  setFilter = (type, value) => {
    this.setState({[type]: value}, () => {
      this.applyFilters();
    })
  }

  setFiltersVisible = (visible) => {

  }

  // filter, search functionality
  applyFilters = () => {
    let businessArr = this.state.businesses;
    let searchTerm = this.state.searchTerm;
    let filters = this.state.activeFilters;
    let open = this.state.open;
    let safespace = this.state.safespace;
    let onlineOnly = this.state.onlineOnly;

    if(searchTerm){
      searchTerm = searchTerm.toLowerCase();
      businessArr = businessArr.filter(function (el) {
        let name = el.companyName ? el.companyName.toLowerCase() : '';
        let industry = el.industry ? el.industry.toLowerCase() : '';
        let description = el.description ? el.description.toLowerCase() : '';

        return (name.includes(searchTerm) ||industry.includes(searchTerm) || description.includes(searchTerm))
      });
    }

    if(onlineOnly){
      businessArr = businessArr.filter((business) => {
        return business.address === 'online';
      })
    }

    if(open){
      const weekday =  moment().format('dddd').toLowerCase();
      const now = moment().format('HHmm');

      businessArr = businessArr.filter((business) => {
        if(business.openingTimes){
          if(business.openingTimes === 'alwaysOpen'){
            return true;
          }
          
          let timesObj = business.openingTimes ? JSON.parse(business.openingTimes) : null;
          
          if(timesObj !== null){
            let dayTimes = Array.from(timesObj[weekday].times);

            for(let timeslot of dayTimes){
              if(timeslot.start && timeslot.end){
                let start = this.formatTimes(timeslot.start);
                let end = this.formatTimes(timeslot.end);

                if(start <= now && end > now){
                  return true;
                }
              }
            }
            
          }

          return false;
        }
        
      })
    }

    if(safespace){
      businessArr = businessArr.filter((business) => {
        return business.safeSpace === 'yes'
      })
    }

    if(filters.size > 0){
        let filteredArray = [];
        for (let business of businessArr){
            if(business.industry){
                let businessIndustries = business.industry.split(/[.,\/ -]/);

                let cleanedIndustries = businessIndustries.filter(function (el) {
                    return el.length > 2;
                });
                

                for (let term of cleanedIndustries) {
                    term = term.toLowerCase();

                    for (let filter of filters){

                      if(industryData[filter].indexOf(term) > -1){
                          if(filteredArray.indexOf(business) < 0){
                            filteredArray.push(business);
                          }
                          break;
                      }
                    }
                }
            }
        }

        businessArr = filteredArray;
    }

    if(this.state.distanceRange > 0 && this.props.distances) {
      let distArray = businessArr.filter((business) => {
        if(!this.props.distances[business.id]){
          return false;
        }

        return this.props.distances[business.id] <= (this.state.distanceRange * 1000);
      })

      businessArr = distArray;
      // console.log(distArray)

    }

    this.setState({filteredBusinesses: businessArr})
  }

  // TODO
  resetAllFilters = () => {
    this.setState({filteredBusinesses: this.state.businesses})
  }

  formatTimes = (value) => {
      let newValue = value.replace(/[ ,.:]+/, '')
      
      switch(newValue.length){
        case 1:
          newValue = '0' + newValue + '00';
          break;
        case 2:
          newValue = newValue + '00';
          break;
        case 3:
          newValue = '0' + newValue;
          break;
        default:
          break;
      }
      return newValue;
  }



  render() {
      // let { mapView } = this.props;
      let currentView;
      let mapView = this.props.mode === 'map' ? true : false;

      if(this.state.favMode){
        currentView = <List businesses={this.state.filteredBusinesses} favMode={true} favourites={this.props.favourites}/>
      } else {
        currentView = mapView ? <HereMap businesses={this.state.filteredBusinesses}/> : <List businesses={this.state.filteredBusinesses} favMode={false} favourites={this.props.favourites} logos={this.state.logos}/>;
      }
      // let currentView = mapView ? <HereMap businesses={this.state.businesses}/> : <List businesses={this.state.businesses} favourites={this.props.favourites} logos={this.state.logos}/>;
      
      return (
        <div className={`container ${mapView ? "map" : "list"}`}>
          {/* {this.state.favMode ? null :
            <ToggleView />
          } */}
          <FilterSearchOptions setFilter={this.setFilter} filtersAreVisible={this.setFiltersVisible} showRadius={this.props.userPosition ? true : false} resetAll={this.resetAllFilters}/>
          {currentView}
          {/* <div className="bottom-message">
              <div>This is just a sneak peek to make our process more transparent for you! We are still working on our app!</div>
          </div> */}
        </div>
      )
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return{
      businesses: state.firestore.ordered.businesses,
      mapView: state.nav.mapView,
      favourites: state.user.favourites,
      distances: state.business.distances
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      saveDistances: (distances) => dispatch(saveDistances(distances)),
      filtersVisible: (visible) => dispatch(filtersVisible(visible))
  }
}

export default compose(
  withFirebase,
  withRouter,
  withTracker,
  HerePlatform,
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
      {
        collection: 'businesses',
        where: [
          ['addOnMap', '==', true]
        ]
    }
  ]) 
)(HomeScreen)