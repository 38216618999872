export const formatTimes = (value) => {
    let newValue = value.replace(/[ ,.:]+/, '')
    
    switch(newValue.length){
      case 1:
        newValue = '0' + newValue + '00';
        break;
      case 2:
        newValue = newValue + '00';
        break;
      case 3:
        newValue = '0' + newValue;
        break;
    }
    return newValue;
}