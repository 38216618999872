import React from 'react';
import {injectIntl} from 'react-intl';
import collage from '../../assets/start_collage.png'

const WelcomeMessage = (props) => {
  return (
    <div id="welcomeMessage">
        <h2>{props.intl.formatMessage({
            id: 'message.welcome.headline',
            defaultMessage: 'Hey there'
        })}</h2>
        <h1>{props.intl.formatMessage({
            id: 'message.welcome.subheadline',
            defaultMessage: 'Welcome to our app'
        })}</h1>
        <img src={collage} alt={props.intl.formatMessage({
            id: 'alt.collageWomen',
            defaultMessage: 'collage of different women'
        })} />
        <p><span>QUEEN OF THE NEIGHBORHOOD</span> {props.intl.formatMessage({
            id: 'message.welcome.paragraph',
            defaultMessage: "QUEEN OF THE NEIGHBORHOOD\nis an independent digital marketplace for women* run, sustainable and alternative businesses."
        })}
        </p>
    </div>
  );
}

export default injectIntl(WelcomeMessage);