import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import pin from '../../assets/map-localization.svg';
import pin_active from '../../assets/map-localization_active.svg';
import bubble from '../../assets/black-bubble-speech.svg';
import world from '../../assets/global_grey.svg';
import queen from '../../assets/queen.png';
import HerePlatform from './HerePlatformHOC';
import '../styles/hereStyles.css'
import '../styles/map.css'
import queen_logo from '../../assets/queen_blink.svg';
import WebLinkIcon from '../profileComponents/WebLinkIcon';
import FavIcon from '../profileComponents/FavIcon';
import SafeSpaceIcon from '../profileComponents/SafeSpaceIcon';
import MapMessage from './MapMessage';
import OpeningTimes from '../profileComponents/OpeningTimes';
import OpenIcon from '../profileComponents/OpenIcon';

export class HereMap extends Component {

    state = {
        locations: [],
        onlineNum: 0,
        activeInfo: null,
        outOfBounds: false
    };

    map = null;
    ui = null;

    componentDidMount () {
        this.initMap()
        // this.getUserPosition();

        if(this.props.businesses){
            this.setLocations(this.props.businesses);
        }

        if(this.props.userPosition !== undefined){
            // console.log(this.props.userPosition)
            this.displayUserPosition(this.props.userPosition);
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if(prevProps.businesses !== this.props.businesses && this.props.businesses){ 
            this.setLocations(this.props.businesses)
        }

        if(prevProps.userPosition !== this.props.userPosition && this.props.userPosition !== undefined){
            // console.log(this.props.userPosition)
            this.displayUserPosition(this.props.userPosition);
        }
    }

    initMap = () => {

        const pixelRatio = window.devicePixelRatio || 1;

        let defaultLayers = this.props.herePlatform.createDefaultLayers({
            tileSize: pixelRatio === 1 ? 256 : 512,
            ppi: pixelRatio === 1 ? undefined : 320
        });

        let reduced = this.props.herePlatform.getMapTileService({
            type: 'base'
        }).createTileLayer("maptile", "reduced.day", 256, "png8");

        // reduced.setMin(12);

        // Instantiate (and display) a map object:
        this.map = new this.props.here.Map(
            this.refs.Map,
            reduced,
            // defaultLayers.normal.map,
            {pixelRatio: pixelRatio}
        );

        let locationsContainer = this.refs.Panel;

        this.ui = new this.props.here.ui.UI(this.map);
        let behavior = new this.props.here.mapevents.Behavior(new this.props.here.mapevents.MapEvents(this.map));

        

        if(!this.state.userPosition){
            // alert("no user pos")
            this.moveMapTo({lat:52.5159, lng:13.3777}, 12, false);
        }

       this.checkBerlin(this.map);
       this.setUpEventListeners(this.map)
        
    }

    moveMapTo = (coord, zoom, animate) => {
        this.map.setCenter(coord, animate);
        this.map.setZoom(zoom, animate);
    }

    restrictMap = (map) => {
        // 13.0883,52.3383,13.7612,52.6755

        let bounds = new this.props.here.geo.Rect(52.3383, 13.0883, 52.6755, 13.7612);
      
        map.getViewModel().addEventListener('sync', function() {
          var center = map.getCenter();
      
          if (!bounds.containsPoint(center)) {
            if (center.lat > bounds.getTop()) {
              center.lat = bounds.getTop();
            } else if (center.lat < bounds.getBottom()) {
              center.lat = bounds.getBottom();
            }
            if (center.lng < bounds.getLeft()) {
              center.lng = bounds.getLeft();
            } else if (center.lng > bounds.getRight()) {
              center.lng = bounds.getRight();
            }
            map.setCenter(center);
          }
        });
    }

    checkBerlin = (map) => {
        let berlin = new this.props.here.geo.Rect(52.3383, 13.0883, 52.6755, 13.7612);
      
        map.getViewModel().addEventListener('sync',() => {
          var center = map.getCenter();
      
          if (!berlin.containsPoint(center)) {
            if (center.lat > berlin.getTop()) {
              center.lat = berlin.getTop();
            } else if (center.lat < berlin.getBottom()) {
              center.lat = berlin.getBottom();
            }
            if (center.lng < berlin.getLeft()) {
              center.lng = berlin.getLeft();
            } else if (center.lng > berlin.getRight()) {
              center.lng = berlin.getRight();
            }
            
            this.setState({'outOfBounds': true})
            
          } else {
            this.setState({'outOfBounds': false})
          }
        });
    }

    displayUserPosition = (position) => {
        if(position && position.coords){
            let location = {lat: position.coords.latitude, lng:position.coords.longitude}
            let bounds = new this.props.here.geo.Rect(52.3383, 13.0883, 52.6755, 13.7612);

            if (bounds.containsPoint(location)) {
                this.setState({"outOfBounds": true})
            }

            let UserIcon = new this.props.here.map.Icon(queen);
            let marker = new this.props.here.map.Marker(location, {icon: UserIcon});
            this.map.addObject(marker);
            this.moveMapTo(location, 15, true);
            // this.displayUserPosition(location);
        }
        
    }

    setUpEventListeners = (map) => {
        // Attach an event listener to map display
        // obtain the coordinates and display in an alert box.
        map.addEventListener('dbltap', function (evt) {
          var coord = map.screenToGeo(evt.currentPointer.viewportX,
                  evt.currentPointer.viewportY);
        //   alert('Clicked at ' + Math.abs(coord.lat.toFixed(4)) +
        //       ((coord.lat > 0) ? 'N' : 'S') +
        //       ' ' + Math.abs(coord.lng.toFixed(4)) +
        //        ((coord.lng > 0) ? 'E' : 'W'));
        });

        map.addEventListener('dragend', (evt) => {
            this.checkBerlin(map)
        })
      }


   setLocations = (businesses) => {
        if(this.state.activeGroup){
            this.state.activeGroup.removeAll();
        }

        let businessArray = [];
        
        if(businesses.length){
            for (let business of businesses){
                if(business.address && business.address !== 'online'){
                    businessArray.push(business);
                }
            }
        }
        // console.log(this.props.businesses.length, businessArray.length)
        this.addLocationsToMap(businessArray)
   }

    addLocationsToMap = (locations) => {
        // let self = this;
        // let locations = this.state.locations;
        let group = new this.props.here.map.Group();
        this.setState({activeGroup: group})

        let QUOTNIcon = new this.props.here.map.Icon(pin);
        let ActiveIcon = new this.props.here.map.Icon(pin_active);

        group.addEventListener('tap', (evt) => {
            // disable tap event listener from map
            evt.stopPropagation();

            if(this.state.activeMarker){
                this.state.activeMarker.icon = QUOTNIcon;
            }

            let data = evt.target.getData();
            evt.target.icon = ActiveIcon;
           
            this.moveMapTo(evt.target.getPosition(), this.map.getZoom(), true)
            this.setState({'activeInfo': data})
            this.setState({'activeMarker': evt.target})
        }, false);

        
        
        for (let loc of locations) {
            // console.log(loc.address)
            let addressObj = JSON.parse(loc.address);
            // console.log(addressObj)

            let position = {
                lat: addressObj.location.displayPosition.latitude,
                lng: addressObj.location.displayPosition.longitude
            };

            let marker = new this.props.here.map.Marker(position, {icon: QUOTNIcon});
            marker.label = addressObj.location.address.label;

            marker.setData({data: loc, addressObj: addressObj, position: position})

            group.addObject(marker);
        }
      
        // Add the locations group to the map
        this.map.addObject(group);
        // this.map.setCenter(group.getBounds().getCenter());
        // this.addInfoBubble(this.map)
    }

    closeInfo = () => {
        let QUOTNIcon = new this.props.here.map.Icon(pin);

        if(this.state.activeMarker){
            this.state.activeMarker.icon = QUOTNIcon;
        }

        this.setState({activeMarker: null, activeInfo: false})
    }

    // openInfo = (data) => {
    //     console.log(data)
    // }

    truncateString = (str, len) => {
        // let trimmedString = string.length > length ? string.substring(0, length - 3) + "..." : string.substring(0, length);
        // return trimmedString;
        if (str.length > len){
            let newLength;
            let append = " ...";  //Optional: append a string to str after truncating. Defaults to an empty string if no value is given
            
            str.length+append.length > len ? newLength = len-append.length : newLength = str.length; // if the length of original string and the appended string is greater than the max length, we need to truncate, otherwise, use the original string
            
            let tempString = str.substring(0, newLength);  //cut the string at the new length
            tempString = tempString.replace(/\s+\S*$/, ""); //find the last space that appears before the substringed text
            
            if (append.length > 0)
            {
                    tempString = tempString + append;
            }
            return tempString;
        } else {
            return str;
        }
    }

    gotoBerlin = () => {
        this.moveMapTo({lat:52.5159, lng:13.3777}, 12, true)
    }

    render() {
        let info = this.state.activeInfo;
        let outOfBounds = this.state.outOfBounds;
        // console.log(info)

        return(
            <div>
                {outOfBounds ? 
                    <MapMessage messageAction={this.gotoBerlin} />
                : null}
                {info ? 
                <div className="info-container">
                    <div className="close" onClick={() => this.closeInfo()}>+</div>
                    {/* <div className="logo" style={{backgroundImage: `url(${this.props.logos[info.data.id] || queen_logo})`}}></div> */}
                    <div>
                        <div className="info">
                            <h2><Link to={`/businesses/${info.data.id}`}>{this.truncateString(info.data.companyName, 45)}</Link></h2>
                            <div className="industry">{this.truncateString(info.data.industry, 50)}</div>
                            <div className="address">
                                <div className="street">{info.addressObj.location.address.street}&nbsp;{info.addressObj.location.address.houseNumber}</div>
                                <div className="postcode">{info.addressObj.location.address.postalCode}&nbsp;{info.addressObj.location.address.city}</div>
                            </div>
                        </div>
                        <OpeningTimes openingTimes={info.data.openingTimes} />
                        <div className="icons">
                            <div className="icons-left">
                                <WebLinkIcon business={info.data}/>
                            </div>
                            <div className="icons-right">
                                <SafeSpaceIcon safe={info.data.safeSpace} />
                                <OpenIcon openingTimes={info.data.openingTimes} />
                                <FavIcon businessId={info.data.id} />
                            </div>
                        </div>
                    </div>
                </div>
                : null }
                <div data-testid="map-container" className="mapContainer" ref="Map" />
                {/* <div className="locationsContainer" ref="Panel" /> */}
            </div>
        )
    }

}

// export default Map;
export default HerePlatform(HereMap);