import React from 'react'
import {injectIntl} from 'react-intl'

const Message = (props) => {
    return (
        <div className="message">
            {props.intl.formatMessage({
                id: props.message,
                defaultMessage: props.message
            })}
        </div>
    )
}

export default injectIntl(Message)