import React, {Component} from 'react'
import {injectIntl} from 'react-intl'
import moment from 'moment';
import { IoMdGift } from 'react-icons/io';
// import industryData from '../../data/industries.json'
// import '../styles/ui.css'

class OpeningTimes extends Component {
    state = {
        expanded: false,
        open: false,
        openingTimes: {}
    }

    componentDidMount() {
        if(this.props.openingTimes){
            this.checkOpen()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.openingTimes !== this.props.openingTimes && this.props.openingTimes){
            this.checkOpen();
        }
    }

    toggleExpanded = () => {
        let state = !this.state.expanded;
        this.setState({expanded: state})
    }

    checkOpen = () => {
        const weekday =  moment().format('dddd').toLowerCase();
        const now = moment().format('HHmm');
        let open = false;

        if(this.props.openingTimes && this.props.openingTimes !== "alwaysOpen"){
            let timesObj = JSON.parse(this.props.openingTimes);
            this.setState({openingTimes: timesObj})
            
            // if(timesObj !== null){
                let dayTimes = Array.from(timesObj[weekday].times);

                for(let timeslot of dayTimes){
                    if(timeslot.start && timeslot.end){
                        let start = this.formatTimes(timeslot.start, false);
                        let end = this.formatTimes(timeslot.end, false);
                        
                        if(start <= now && end > now){
                            open = true;
                        }
                    }
                }

               
            // }
        } else if(this.props.openingTimes && this.props.openingTimes !== "alwaysOpen"){
            open = true;
        }

        this.setState({open: open})
    }

    formatTimes = (value, toHours) => {
        if(value){
            let newValue = value.replace(/[ ,.:]+/, '')
            
            switch(newValue.length){
            case 1:
                newValue = '0' + newValue + '00';
                break;
            case 2:
                newValue = newValue + '00';
                break;
            case 3:
                newValue = '0' + newValue;
                break;
            }

            let hours = newValue[0].toString() + newValue[1].toString() + ":" + newValue[2].toString() + newValue[3].toString();

            let time = toHours ? hours : newValue;
            return time;
        }
    }
    
    render(){
       let title = this.state.open ? "openNow" : "openingTimes";
        
        if(!this.props.openingTimes){
            return null;
        }

        return (
            <div className={`openingTimes ${this.state.expanded ? "expanded" : ''}`}>
                <span onClick={this.toggleExpanded}>{this.props.intl.formatMessage({
                        id: title,
                        defaultMessage: "opening times"
                    })}
                    <i className="material-icons">arrow_drop_down</i>
                </span>
                <div>
                {Object.keys(this.state.openingTimes).map((day) =>
                    <div key={day} className="day">
                        <h4>{this.props.intl.formatMessage({
                            id: `abbr.${day}`,
                            defaultMessage: day
                        })} </h4>
                        {this.state.openingTimes[day].closed ? 
                            <div>{this.props.intl.formatMessage({
                                id: "closed",
                                defaultMessage: "closed"
                            })}</div>
                        : null}
                        {this.state.openingTimes[day].times ? Object.keys(this.state.openingTimes[day].times).map((times, index) =>
                            <div key={index} className={this.state.openingTimes[day].times[index].start && this.state.openingTimes[day].times[index].end ? "show" : "hide"}>
                                <span>{this.formatTimes(this.state.openingTimes[day].times[index].start, true)}</span>
                                <span> - </span>
                                <span>{this.formatTimes(this.state.openingTimes[day].times[index].end, true)}</span>
                            </div>
                        ) : null}
                    </div>)}
                </div>
            </div>
        )
    }
}


export default injectIntl(OpeningTimes)