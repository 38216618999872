import React, {Component} from 'react'
import {connect} from 'react-redux'
import { compose } from 'redux'
import { withRouter, Redirect } from 'react-router-dom'
import {sendPasswordResetMail} from '../../redux/actions/authActions'
import InputElem from '../formComponents/InputElem'
import {injectIntl} from 'react-intl'

class ForgotPassword extends Component {
    state = {
        email: ''
    }
    
    handleChange = (target) => {
        this.setState({
            [target.id]: target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.sendPasswordResetMail(this.state.email)
    }

    render() {
        const {auth, authError, emailHandlerState, emailHandlerMode, emailHandlerError} = this.props;

        if(auth.uid){
            return (
                <Redirect to="/settings" />
            )
        } else {
            return (
                <div className="container forgot-password">
                    <h2>{this.props.intl.formatMessage({
                        id: "headline.resetPassword",
                        defaultMessage: "Reset Password"
                    })}</h2>
                    {emailHandlerState !== 'default' ?
                        <div className="email-handler-message">
                            <div className="state">{this.props.intl.formatMessage({
                            id: "message." + emailHandlerMode + "." + emailHandlerState,
                            defaultMessage: emailHandlerState
                        })}</div>
                        <div className="email-handler-error">{authError || emailHandlerError || null}</div>
                        </div>
                    : null }
                    <form onSubmit={this.handleSubmit}>
                        <InputElem inputType="email" inputId="email" inputChanged={this.handleChange} />
                        <button>{this.props.intl.formatMessage({
                            id: "button.resetPassword",
                            defaultMessage: "Reset"
                        })}</button>
                        <div>
                            {/* {authError ? <span>{authError}</span> : null} */}
                        </div>
                    </form>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth,
        authError: state.auth.authError,
        emailHandlerMode: state.auth.emailHandlerMode,
        emailHandlerState: state.auth.emailHandlerState,
        emailHandlerError: state.auth.emailHandlerError
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        sendPasswordResetMail: (email) => dispatch(sendPasswordResetMail(email))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    injectIntl
)(ForgotPassword)