export const fetchUserData = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firebase = getFirebase();

        if(firebase.auth().currentUser) {
            let userId = firebase.auth().currentUser.uid;
            const firestore = getFirestore();

            let userRef = firestore.collection('users').doc(userId);
            let bizRef = firestore.collection('businesses').doc(userId);

            userRef.get()
            .then(doc => {
                if (doc.exists && doc.data()) {
                    dispatch({
                        type: 'USERDATA_FETCH_PRIVATE_SUCCESS',
                        payload: doc.data()
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });

            bizRef.get()
            .then(doc => {
                if (doc.exists && doc.data()) {
                    dispatch({
                        type: 'USERDATA_FETCH_BUSINESS_SUCCESS',
                        payload: doc.data()
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            });

        } else {
            dispatch({type: 'USERDATA_FETCH_ERROR', error: "error.notAuthenticated" });
        }
    }
}

export const fetchFavourites = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firebase = getFirebase();

        if(firebase.auth().currentUser) {
            let userId = firebase.auth().currentUser.uid;
            const firestore = getFirestore();
            let favRef = firestore.collection('users').doc(userId);

            favRef.get()
            .then(doc => {
                if (doc.exists && doc.data().favourites) {
                    dispatch({
                        type: 'FAV_FETCH_SUCCESS',
                        payload: doc.data().favourites
                    })
                } else {
                    dispatch({type: 'FAV_FETCH_ERROR', error: "error.noData" });
                }
            })
            .catch((err) => {
                dispatch({type: 'FAV_FETCH_ERROR', err });
            });
        } else {
            dispatch({type: 'FAV_FETCH_ERROR', error: "error.notAuthenticated" });
        }
    }
}

export const storeFavourite = (businessId) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        
        const firebase = getFirebase();

        if(firebase.auth().currentUser) {
            let userId = firebase.auth().currentUser.uid;
            const firestore = getFirestore();
            let favRef = firestore.collection('users').doc(userId);

            favRef.get()
            .then(doc => {
                if (doc.exists && doc.data().favourites !== undefined) {
                    if(doc.data().favourites.indexOf(businessId) > -1){
                        favRef.update({
                            favourites: firestore.FieldValue.arrayRemove(businessId)
                        })
                        .then(() => {
                            favRef.get()
                            .then(doc => {
                                dispatch({
                                    type: 'FAV_CHANGED',
                                    payload: doc.data().favourites
                                })
                            })
                        }).catch((err) => {
                            dispatch({type: 'FAV_CHANGED_ERROR', err });
                        });
                    } else {
                        favRef.update({
                            favourites: firestore.FieldValue.arrayUnion(businessId)
                        })
                        .then(() => {
                            favRef.get()
                            .then(doc => {
                                dispatch({
                                    type: 'FAV_CHANGED',
                                    payload: doc.data().favourites
                                })
                            })
                        }).catch((err) => {
                            dispatch({type: 'FAV_CHANGED_ERROR', err });
                        });
                    }
                } else {
                    favRef.set(
                        { favourites: [businessId] },
                        { merge: true }
                    )
                    .then(() => {
                        dispatch({
                            type: 'FAV_CHANGED',
                            payload: doc.data().favourites
                        })
                    }).catch((err) => {
                        console.log(err)
                        dispatch({type: 'FAV_CHANGED_ERROR', err });
                    });
                }
            })
            .catch(err => {
                console.log(err)
                dispatch({type: 'FAV_ERROR', err });
            });
        } else {
            dispatch({type: 'LOGIN_ERROR'});
        }
    }
}

export const updateProfile = (profileData, type, logo) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        
        const firebase = getFirebase();

        if(firebase.auth().currentUser) {
            let userId = firebase.auth().currentUser.uid;
            const firestore = getFirestore();
            let userType = (type === 'business') ? 'businesses' : 'users';
            let docRef = firestore.collection(userType).doc(userId);
            let storagePath = 'userUploads/' + userId + '/';
            // let downloadURL;
            // console.log(logo)
            if(logo){
                firebase.uploadFile(
                    storagePath,
                    logo
                ).then(() => {
                    dispatch({
                        type: 'PROFILE_CHANGE_START'
                    })
                    let storageRef = firebase.storage().ref(`userUploads/${userId}/${logo.name}`);
                    storageRef.getDownloadURL().then((url) => {
                        profileData.downloadURL = url;

                        docRef.set(
                            profileData,
                            {merge: true}
                        ).then(() => {
                            dispatch({
                                type: 'PROFILE_CHANGE_SUCCESS'
                            })
                        }).catch((err) => {
                            dispatch({type: 'PROFILE_CHANGE_ERROR', err });
                        });
                    }).catch((err) => {
                        dispatch({
                            type: 'PROFILE_CHANGE_ERROR',
                            err
                        })
                    });
                }).catch((err) => {
                    dispatch({
                        type: 'PROFILE_CHANGE_ERROR',
                        err
                    })
                })
            } else {
                docRef.set(
                     profileData,
                     {merge: true}
                ).then(() => {
                    dispatch({
                        type: 'PROFILE_CHANGE_SUCCESS'
                        // payload: doc.data().favourites
                    })
                }).catch((err) => {
                    console.log(err)
                    dispatch({type: 'PROFILE_CHANGE_ERROR', err });
                });
            }
        } else {
            dispatch({type: 'LOGIN_ERROR'});
        }
    }
}

export const updateLogo = (logo, type) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        
        const firebase = getFirebase();

        if(firebase.auth().currentUser) {
            let userId = firebase.auth().currentUser.uid;
            const firestore = getFirestore();
            type = (type === 'business') ? 'businesses' : 'users';
            let docRef = firestore.collection('businesses').doc(userId);
            let storagePath = 'userUploads/' + userId + '/';

            firebase.uploadFile(
                storagePath,
                logo
            ).then(resp => {
                dispatch({
                    type: 'CHANGE_LOGO_START'
                })
                let storageRef = firebase.storage().ref(`userUploads/${userId}/${logo.name}`);
                storageRef.getDownloadURL().then((url) => {
                    docRef.update(
                        { 'logoURL': url },
                        { merge: true }
                    ).then(() => {
                        dispatch({
                            type: 'CHANGE_LOGO_SUCCESS'
                        })
                    }).catch((err) => {
                        console.log('error saving url')
                        dispatch({
                            type: 'CHANGE_LOGO_ERROR',
                            err
                        })
                    });
                }).catch((err) => {
                    console.log('error getting url')
                    dispatch({
                        type: 'CHANGE_LOGO_ERROR',
                        err
                    })
                });
            }).catch((err) => {
                console.log('error uploading')
                dispatch({
                    type: 'CHANGE_LOGO_ERROR',
                    err
                })
            });
            // .then(
            //     docRef.set(
            //         { profileData },
            //         { merge: true }
            //     ).then(() => {
            //         dispatch({
            //             type: 'PROFILE_CHANGED_SUCCESS'
            //             // payload: doc.data().favourites
            //         })
            //     }).catch((err) => {
            //         console.log(err)
            //         dispatch({type: 'PROFILE_CHANGED_ERROR', err });
            //     });
            // )


            
        } else {
            console.log("auth error")
            dispatch({type: 'LOGIN_ERROR'});
        }
    }
}

export const dispatchMessage = (message) => {
    return (dispatch) => {
        dispatch({type: message});
    }
}