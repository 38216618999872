import React, {Component} from 'react'
import {connect} from 'react-redux'
// import { compose } from 'redux'
import {withRouter, NavLink} from 'react-router-dom'
import pin from '../../assets/map-localization-white.svg';
import pin_active from '../../assets/map-localization_active.svg';
// import SignedInLinks from './SignedInLinks'
// import SignedOutLinks from './SignedOutLinks'
// import { render } from 'react-testing-library';

// const isActive = (path, match, location) => !!(match || path === location.pathname);

class NavBar extends Component {
    isActive = (path, match, location) => !!(match || path === location.pathname);
    // const links = auth.emailVerified ? <SignedInLinks /> : null;
    // TODO: Update nav on prop change
    render() {   

        const { auth } = this.props;
        // const links = auth.uid ? <SignedInLinks /> : <SignedOutLinks />;
        // alert(this.props.location.pathname)
        return (
            <nav className="navBar">
                {this.props.history.length > 1 ? 
                    <div onClick={this.props.history.goBack}>
                        <i className="flaticon-left-arrow"></i>
                    </div>
                : null}
                <NavLink className="tab" exact to='/' isActive={this.isActive.bind(this, '/')}>
                    <i className="flaticon-house"></i>
                </NavLink>
                <NavLink className="tab" exact to='/businesses/list' isActive={this.isActive.bind(this, '/businesses/list')}>
                    <i className="flaticon-list"></i>
                </NavLink>
                <NavLink className="tab" exact to='/businesses/map' isActive={this.isActive.bind(this, '/businesses/map')}>
                    <i>
                        <img className="pin_active" src={pin_active} />
                        <img className="pin_normal" src={pin} />
                    </i>
                </NavLink>
                {auth.uid ? 
                    <NavLink className="tab" to='/businesses/favlist' isActive={this.isActive.bind(this, '/businesses/favlist')}>
                        <i className="flaticon-heart"></i>
                    </NavLink>
                : null }
                {auth.uid ? 
                    <NavLink className="tab" to='/settings' isActive={this.isActive.bind(this, '/settings')}>
                        <i className="flaticon-gear"></i>
                    </NavLink>
                :   <NavLink className="tab" to='/auth' isActive={this.isActive.bind(this, '/auth')}>
                        <i className="flaticon-user"></i>
                    </NavLink>
                }
                {!auth.uid ?
                    <NavLink className="tab" exact to='/settings' isActive={this.isActive.bind(this, '/settings')}>
                        <i className="flaticon-information-button"></i>
                    </NavLink>
                : null }
                {/* <div className="linkContainer">
                    <a className="imprint" target="_blank" rel="noopener noreferrer" href="https://www.quotn.co/imprint.html">Imprint</a>
                    { links }
                </div> */}
            </nav>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state.firebase);
    return{
        auth: state.firebase.auth
    }
}

export default withRouter(connect(mapStateToProps)(NavBar))