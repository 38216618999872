import {combineReducers} from 'redux'
import {firestoreReducer} from 'redux-firestore'
import {firebaseReducer} from 'react-redux-firebase'
import authReducer from './authReducer'
import navReducer from './navReducer'
import userReducer from './userReducer'
import businessReducer from './businessReducer'

const rootReducer = combineReducers({
    auth: authReducer,
    nav: navReducer,
    user: userReducer,
    business: businessReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
});

export default rootReducer;