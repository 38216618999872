import React, {Component} from 'react'
import moment from 'moment';

class OpenIcon extends Component {

    state = {
        open: false
    }

    componentDidMount() {
        if(this.props.openingTimes){
            this.checkOpen()
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.openingTimes !== this.props.openingTimes && this.props.openingTimes){
            this.checkOpen();
        }
    }
    
    checkOpen = () => {
        const weekday =  moment().format('dddd').toLowerCase();
        const now = moment().format('HHmm');
        let open = false;

        if(this.props.openingTimes && this.props.openingTimes !== "alwaysOpen"){
            let timesObj = JSON.parse(this.props.openingTimes);
            
            // if(timesObj !== null){
                let dayTimes = Array.from(timesObj[weekday].times);

                for(let timeslot of dayTimes){
                    if(timeslot.start && timeslot.end){
                        let start = this.formatTimes(timeslot.start);
                        let end = this.formatTimes(timeslot.end);
                        
                        if(start <= now && end > now){
                            open = true;
                        }
                    }
                }

               
            // }
        } else if(this.props.openingTimes && this.props.openingTimes !== "alwaysOpen"){
            open = true;
        }

        this.setState({open: open})
    }

    formatTimes = (value) => {
        let newValue = value.replace(/[ ,.:]+/, '')
        
        switch(newValue.length){
          case 1:
            newValue = '0' + newValue + '00';
            break;
          case 2:
            newValue = newValue + '00';
            break;
          case 3:
            newValue = '0' + newValue;
            break;
        }
        return newValue;
    }

    render(){
        if(this.state.open){
            return (
                <i className="flaticon-hour"></i>
            )
        } else {
            return null
        }
    }
}


export default OpenIcon