import React, {Component} from 'react'
import {connect} from 'react-redux'
import { compose } from 'redux'
import { storeFavourite } from '../../redux/actions/userActions'
import { openPrompt } from '../../redux/actions/navActions'

class FavIcon extends Component {

    state = {
        selected: false
    }

    componentDidMount() {
        this.checkFav()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.favourites !== this.props.favourites || this.props.businessId !== prevProps.businessId){
            this.checkFav()
        }
    }

    checkFav = () => {
        let { favourites, businessId } = this.props;
        if(favourites && businessId && favourites.indexOf(businessId) > -1){
            this.setState({selected: true})
        } else {
            this.setState({selected: false})
        }
    }

    toggleFav = () => {
        if(this.props.uid){
            let selected = !this.state.selected;
            this.setState({selected: selected})
            this.props.storeFavourite(this.props.businessId)
        } else {
            this.openLoginPrompt();
        }
    }

    openLoginPrompt = () => {
        let promptData = {
            headline: "message.notLoggedIn",
            content: "message.pleaseLogIn",
            buttonText: "ok",
            linkTo: "/auth/login"
        }
        
        this.props.openPrompt(promptData)
    }

    render(){
        return (
            <i onClick={this.toggleFav} className={this.state.selected ? "flaticon-heart" : "flaticon-heart-1"}></i>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        uid: state.firebase.auth.uid,
        favourites: state.user.favourites
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        storeFavourite: (businessId) => dispatch(storeFavourite(businessId)),
        openPrompt: (data) => dispatch(openPrompt(data))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(FavIcon)