import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

class FileUpload extends Component {
    state = {
        value: '',
        error: null,
        edited: false
    }

   static getDerivedStateFromProps(props, state) {
        if (state.edited) {
          return {};
        }

        if(props.inputData && props.inputData.length > 0){
            return {value: props.inputData};
        }

        return {}
    }

    componentDidMount() {
        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }

        if(this.state.value !== ''){
            this.renderImage(this.state.value)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.value !== this.state.value && this.state.value === this.props.inputData){
            this.renderImage(this.state.value)
        }
    }

    // inputChanged = (e) => {
    //     let file = e.target.files[0];

    //     // this.validate()

    //     this.setState({value: file.name}, () => {
    //         this.renderImage(file)
            
    //     })
    // }

    renderImage = (file) => {
        if(typeof(file) !== 'string'){
            // generate a new FileReader object
            var reader = new FileReader();
                
            // inject an image with the src url
            reader.onload = (event) => {
                let tempFile = event.target.result
                this.setState({'tempFile': tempFile});
            }

            // when the file is read it triggers the onload event above.
            reader.readAsDataURL(file);
        } else {
            this.setState({'tempFile': file});
        }
      }

    validate = (e) => {
        console.log(e.target.value)

        if(!this.state.edited){ 
            this.setState({edited: true})
        }

        let err_msg = null;
        let valid = true;
        let file = e.target.files[0];

        if(file){
            if(this.props.required && !file){
                err_msg = 'error.required';
                valid = false;
            }
            
            if(e.target.files.length > 1){
                err_msg = 'error.fileLimit';
                valid = false;
            }

            if(file && file.size > 500000){
                err_msg = 'error.fileSize';
                valid = false;
            }

            this.setState({'error': err_msg}, () => {
                if(this.props.registerUpdate){
                    this.props.registerUpdate(this.props.inputId, valid);
                }
            })

            if(valid){
                this.setState({'value': file}, () => {
                    this.props.saveData(this.props.inputId, this.state.value)
                    this.renderImage(file)
                })
            }
        }
    }

    render() {
        return (
            <div className={`inputContainer ${this.props.inputId} ${this.state.error ? 'hasError' : null}`}>
                <label className="file">
                    {this.props.hideLabel ? null :
                        <span className="labelText">{this.props.intl.formatMessage({
                            id: `label.${this.props.inputLabel || this.props.inputId}`,
                            defaultMessage: `label.${this.props.inputId}`
                        })}{this.props.required ? ' *' : null}</span>
                    }
                    <div id="logo-img">
                        <div className="logo-container" style={{'backgroundImage': `url("${this.state.tempFile}")`}}>
                            {/* <img src={this.state.tempFile} alt="logo"/> */}
                        </div>
                        <span className="fileName">{this.state.value.name}</span>
                    </div>
                    <input type="file" id={this.props.inputId} onChange={this.validate} accept="image/gif, image/jpeg, image/png"/>
                </label>
                {this.state.error ?
                    <div className="errorMessage">{this.props.intl.formatMessage({
                        id: this.state.error || 'error',
                        defaultMessage: 'This field is not filled out correctly.'
                    })}</div>
                : null}
            </div>
        );
    }
}

FileUpload.propTypes = {
    inputId: PropTypes.string.isRequired,
    required: PropTypes.bool
}


export default injectIntl(FileUpload);
