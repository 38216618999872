const initState = {
    authError: null,
    registerState: 'default',
    emailHandlerMode: 'default',
    emailHandlerState: 'default',
    emailHandlerData: null,
    emailHandlerError: null,
    authMessage: null
}

const authReducer = (state = initState, action) => {
    switch(action.type){
        case 'LOGIN_ERROR':
        console.log('login error')
            return {
                ...state,
                authError: action.err.message,
                authMessage: "login.error"
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                authError: null,
                emailHandlerMode: 'default',
                emailHandlerState: 'default',
                emailHandlerData: null,
                emailHandlerError: null,
                authMessage: null
            }
        case 'SIGNOUT_SUCCESS':
            return {
                ...state,
                authMessage: "signout.success"
            }
        case 'SIGNUP_LOADING':
            return {
                ...state,
                registerState: 'loading',
                authError: null,
                authMessage: "signup.loading"
            }
        case 'SIGNUP_SUCCESS':
            console.log('signed up!')
            return {
                ...state,
                registerState: 'registered',
                authError: null,
                emailHandlerMode: 'default',
                emailHandlerState: 'default',
                emailHandlerData: null,
                emailHandlerError: null,
                authMessage: "signup.success"
            }
        case 'SIGNUP_ERROR':
            console.log(action.err)
            return {
                ...state,
                registerState: 'error',
                authError: action.err.message,
                authMessage: "signup.error"
            }
        case 'SIGNUP_VER_EMAIL_SENT':
            return {
                ...state,
                registerState: 'verification_pending',
                authMessage: "signup.email.sent"
            } 
        case 'SIGNUP_VER_EMAIL_ERROR':
            return {
                ...state,
                registerState: 'error',
                authError: action.err.message,
                authMessage: "signup.email.error"
            }
        case 'VERIFY_EMAIL':
            return {
                ...state,
                // registerState: 'verified',
                emailHandlerMode: 'verify_email',
                emailHandlerState: 'success',
                emailHandlerError: null,
                authMessage: null
            }
        case 'VERIFY_EMAIL_ERROR':
            return {
                ...state,
                emailHandlerMode: 'verify_email',
                emailHandlerState: 'error',
                emailHandlerError: action.err.message,
                authMessage: "verify.email.error"
            }
        case 'VER_EMAIL_LOADING':
            return {
                ...state,
                emailHandlerMode: 'resend_verification',
                emailHandlerState: 'loading',
                emailHandlerError: null,
                authMessage: "verify.email.loading"
            }
        case 'VER_EMAIL_SENT':
            return {
                ...state,
                registerState: 'verify',
                emailResent: 'sent',
                emailHandlerMode: 'resend_verification',
                emailHandlerState: 'sent',
                emailHandlerError: null,
                authMessage: "verify.email.sent"
            }
        case 'VER_EMAIL_ERROR':
            return {
                ...state,
                emailHandlerMode: 'resend_verification',
                emailHandlerState: 'error',
                emailHandlerError: action.err.message,
                authMessage: "verify.email.error"
            }
        case 'UPDATE_EMAIL_SUCCESS':
            return {
                ...state,
                emailHandlerMode: 'update_email',
                emailHandlerState: 'success',
                emailHandlerError: null,
                authMessage: "updateEmail.success"
            }
        case 'UPDATE_EMAIL_ERROR':
            return {
                ...state,
                emailHandlerMode: 'update_email',
                emailHandlerState: 'error',
                emailHandlerError: action.err.message,
                authMessage: "updateEmail.error"
            }
        case 'RESTORE_EMAIL_SUCCESS':
            return {
                ...state,
                emailHandlerMode: 'restore_email',
                emailHandlerState: 'success',
                emailHandlerError: null,
                authMessage: "restoreEmail.success"
            }
        case 'RESTORE_EMAIL_ERROR':
            return {
                ...state,
                emailHandlerMode: 'restore_email',
                emailHandlerState: 'error',
                emailHandlerError: action.err.message,
                authMessage: "restoreEmail.error"
            }
        case 'PW_RESET_EMAIL_LOADING':
            return {
                ...state,
                emailHandlerMode: 'reset_pw',
                emailHandlerState: 'loading',
                emailHandlerError: null,
                authMessage: "pwReset.email.loading"
            }
        case 'PW_RESET_EMAIL_SENT':
            return {
                ...state,
                emailHandlerMode: 'reset_pw',
                emailHandlerState: 'sent',
                authMessage: "pwReset.email.sent"
            }
        case 'PW_RESET_EMAIL_ERROR':
            return {
                ...state,
                emailHandlerMode: 'reset_pw',
                emailHandlerState: 'error',
                emailHandlerError: action.err.message,
                authMessage: "pwReset.email.error"
            }
        case 'PW_RESET_START':
            console.log("pw reset start")
            return {
                ...state,
                emailHandlerMode: 'reset_pw',
                emailHandlerState: 'start',
                emailHandlerData: action.payload,
                emailHandlerError: null,
                authMessage: "pwReset.start"
            }
        case 'PW_RESET_SUCCESS':
            return {
                ...state,
                emailHandlerMode: 'reset_pw',
                emailHandlerState: 'success',
                emailHandlerError: null,
                authMessage: "pwReset.success"
            }
        case 'PW_RESET_ERROR':
            return {
                ...state,
                emailHandlerMode: 'reset_pw',
                emailHandlerState: 'error',
                emailHandlerError: action.err.message,
                authMessage: "pwReset.error"
            }
        case 'RESET_AUTH_MESSAGE':
            return {
                ...state,
                authMessage: null
            }
        default:
            return state;
    }
}

export default authReducer