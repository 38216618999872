const initState = {
    mapView: false,
    promptOpen: false,
    promptData: {},
    filtersVisible: false
}

const navReducer = (state = initState, action) => {
    switch(action.type){
        case 'TOGGLE_MAP_VIEW':
            return {
                ...state,
                mapView: !state.mapView
            }
        case 'OPEN_PROMPT':
            return {
                ...state,
                promptOpen: true,
                promptData: action.data
            }
        case 'CLOSE_PROMPT':
            return {
                ...state,
                promptOpen: false,
                promptData: {}
            }
        case 'FILTERS_VISIBLE_CHANGED':
            return {
                ...state,
                filtersVisible: action.visible
            }
        default:
            return state;
    }
}

export default navReducer;