import React, {Component} from 'react'
import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import { compose } from 'redux'
import { withRouter, Link } from 'react-router-dom'
import {signUp} from '../../redux/actions/authActions'
import InputElem from '../formComponents/InputElem'
import update from 'immutability-helper'
import '../styles/styles.css'
import inputData from '../../data/inputData.json'
import OpeningTimes from '../formComponents/OpeningTimes';
import InputAddress from '../formComponents/InputAddress';
import RadioButtonGroup from '../formComponents/RadioButtonGroup';
import Checkbox from '../formComponents/Checkbox';
import SignupMessage from '../auth/components/SignupMessage';

import addressIllu from '../../assets/signup/address.jpg';
import clockIllu from '../../assets/signup/clock.jpg';
import wwwIllu from '../../assets/signup/www.jpg';
import signUpIllu from '../../assets/signup/signup_b.jpg';
import verifiedIllu from '../../assets/signup/verified.jpg';

import smallCompIllu from '../../assets/signup/small_comp.jpg';
import mediumCompIllu from '../../assets/signup/med_comp.jpg';
import largeCompIllu from '../../assets/signup/large_comp.jpg';
import TextInput from '../formComponents/TextInput';
// import {fetchUserData} from '../../redux/actions/userActions'
import TextArea from '../formComponents/TextArea';
import Password from '../formComponents/Password';
import Dropdown from '../formComponents/Dropdown';
import FileUpload from '../formComponents/FileUpload';
import SDGs from '../formComponents/SDGs';
// import NewsletterSignup from '../formComponents/NewsletterSignup';

class SignUpForm extends Component {
    state = {
        signUpData: {
            type : null,
            creds : {
                email: '',
                password: ''
            },
            details: {},
            logo: ''
        },
        progress: 0,
        requiredInputs: {},
        missingFields: [],
        error: false,
        trySend: false,
        readyForSave: true
    }

    componentDidMount () {
        const { type } = this.props.match.params;

        let signUpData = update(this.state.signUpData, {
            'type': {$set: type}
        });

        this.setState({signUpData: signUpData});
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if(this.props.authError && (!prevProps.authError || prevProps.authError !== this.props.authError)){
        //    alert(this.props.authError) 
        }

        if(!prevProps.userData && this.props.userData){
            //TODO: store initial data ? maybe just update the changed ones
        }
    }

    registerUpdateInput = (input, valid) => {
        let inputs = this.state.requiredInputs;
        inputs[input] = valid;

        this.setState({requiredInputs: inputs}, () => {
            this.calculateProgress();
        })
    }

    saveData = (id, value) => {
        let newData;
        console.log(this.state.signUpData)

        if(id === 'email' || id === 'password'){
            newData = update(this.state.signUpData, {
                creds: {
                    [id]: {$set: value}
                }
            });
        } else {
            newData = update(this.state.signUpData, {
                details: {
                    [id]: {$set: value}
                }
            })
        };
        
        
        this.setState({'signUpData': newData}, () => {
            // this.setState({'readyForSave': true})
            this.calculateProgress();
        });
    }

    saveFile = (id, file) => {
        let signUpData = update(this.state.signUpData, {
            logo: {$set: file},
            details: {
                logo: {$set: file.name}
            }
        })

        this.setState({'signUpData': signUpData}, () => {
            // this.setState({'readyForSave': true})
            this.calculateProgress();
        });
    }

    calculateProgress = () => {
        // console.log("calculating")
        let progressNum = 0;
        let totalNum = 0;
        let missingFields = []

        for(const [input, valid] of Object.entries(this.state.requiredInputs)){
            totalNum ++;
            if(valid === true){
                progressNum ++;
            } else {
                missingFields.push(input)
            }
        }

        let progress = (progressNum / totalNum) * 100;
        this.setState({'progress': progress, 'missingFields': missingFields}, () => {
            if(this.state.trySend && progress === 100){
                this.submitForm();
            } else if(this.trySend){
                this.setState({trySend: false})
            } else if(!this.state.trySend && progress === 100){
                this.setState({'error': false})
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let error = !!this.state.missingFields.length;
        // get errors from inputs
        
        this.setState({'error': error, 'trySend': !error}, () => {})
            
        this.calculateProgress();
    }

    submitForm = () => {
        this.setState({'error': false}, () => {
            this.props.signUp(this.state.signUpData);
        })
    }

    render() {
        const {authError, registerState} = this.props;
        const percentage = this.state.progress + "%";
        const type = this.props.match.params.type || this.props.userType;
        const companySizeImages = [smallCompIllu, mediumCompIllu, largeCompIllu];

        let ErrorMessage = (
            <div className="errorMessages">
                <div>
                    {this.props.intl.formatMessage({
                        id: "error.completeForm",
                        defaultMessage: "Please fill out the following fields:"
                    })}
                </div>
                {this.state.missingFields.map((title, index) => 
                    <div key={index}>
                        {this.props.intl.formatMessage({
                            id: `label.${title}`,
                            defaultMessage: title
                        })}
                </div>)}
            </div>)

        let AuthErrorMessage = (<div className="errorMessages"><div className="error">{authError}</div></div>)

        if (registerState === 'loading') {
            return <h3>{this.props.intl.formatMessage({
                id: 'message.signup.loading',
                defaultMessage: 'You are being signed up. Please wait a moment.'
            })}</h3>
        }

        if (registerState === 'verification_pending' && this.state.signUpData.type === "business") {
            return (
                <div className="container verifyMessage">
                    <h3>{this.props.intl.formatMessage({
                            id: 'message.registered.headline',
                            defaultMessage: 'Congrats you are amongst the first 100 to sign up!'
                        })}
                    </h3>
                    <p>{this.props.intl.formatMessage({
                            id: 'message.signup.verify',
                            defaultMessage: 'We have sent you an email. Please verify your account.'
                        })}
                    </p>
                    <Link to="/">{this.props.intl.formatMessage({
                        id: 'link.register.map',
                        defaultMessage: 'Check out who else signed up already'
                    })}</Link>
                    <img src={verifiedIllu} alt={this.props.intl.formatMessage({
                        id: 'alt.verifiedIllu',
                        defaultMessage: 'Woman doing high five gesture'
                    })}/>
                </div>
            )
        }

        if (registerState === 'verification_pending' && this.state.signUpData.type === "private") {
            return (
                <div className="container verifyMessage">
                    <h3>{this.props.intl.formatMessage({
                            id: 'message.registered.headline.private',
                            defaultMessage: 'Thanks for signing up!'
                        })}
                    </h3>
                    <p>{this.props.intl.formatMessage({
                            id: 'message.signup.verify',
                            defaultMessage: 'We have sent you an email. Please verify your account.'
                        })}
                    </p>
                    <Link to="/">{this.props.intl.formatMessage({
                        id: 'link.register.map.private',
                        defaultMessage: 'Check out the businesses who have signed up so far'
                    })}</Link>
                    <img src={verifiedIllu} alt={this.props.intl.formatMessage({
                        id: 'alt.verifiedIllu',
                        defaultMessage: 'Woman doing high five gesture'
                    })}/>
                </div>
            )
        }
        
        return (
            <div className="container signup">
                <div className="progressBar">
                    <div className="progress" style={{width: percentage}}></div>
                </div>
                <SignupMessage regType={this.state.signUpData.type} />
                {type === 'business' ? 
                    <form ref={this.signupForm} onSubmit={this.handleSubmit}>
                        <TextInput inputId="companyName" saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} autoComp="org"/>
                        <div className="illufield">    
                            <div className="illustration">
                                <img src={addressIllu} alt={this.props.intl.formatMessage({
                                    id: 'alt.addressIllu',
                                    defaultMessage: 'Illustration of company building'
                                })} />
                            </div>
                            <InputAddress inputId="address" saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} autoComp="street-address"/>
                        </div>
                        <TextArea inputId="description" saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} maxLength="100" />
                        <TextInput inputId="industry" saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error}/>
                        <div className="illufield">
                            <OpeningTimes inputId="openingTimes" saveData={this.saveData} registerUpdate={this.registerUpdateInput} required={true} showError={this.state.error}/>
                            <div className="illustration">
                                <img src={clockIllu} alt={this.props.intl.formatMessage({
                                    id: 'alt.clockIllu',
                                    defaultMessage: 'Illustration of clock'
                                })} />
                            </div>
                        </div>
                        <RadioButtonGroup inputId="safeSpace" options={['yes', 'no']} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} />
                        <div className="illufield">
                            <div className="illustration">
                                <img src={wwwIllu} alt={this.props.intl.formatMessage({
                                    id: 'alt.laptopIllu',
                                    defaultMessage: 'Illustration of laptop computer'
                                })} />
                            </div>
                            <TextInput inputId="website" saveData={this.saveData} required={false} autoComp="url"/>
                        </div>
                        <TextInput inputType="number" inputId="foundationYear" saveData={this.saveData} required={false} />
                        <Dropdown inputId="legalForm" withSearch={false} inputOptions={inputData.legalForms} saveData={this.saveData} required={false} />
                        <RadioButtonGroup inputId="companySize" options={inputData.companySizes} images={companySizeImages} saveData={this.saveData}/>
                        <div className={`logoGroup display-${this.state.signUpData.details.logoFill} bg-${this.state.signUpData.details.logoBackground}`}> 
                            <FileUpload inputId="logo" saveData={this.saveFile} />
                            <div className="logoOptions">
                                <RadioButtonGroup inputId="logoFill" options={['fill', 'fit']} saveData={this.saveData} />
                                <RadioButtonGroup inputId="logoBackground" options={['black', 'white']} saveData={this.saveData} />
                            </div>
                        </div>
                        <RadioButtonGroup inputId="founderGender" options={['femaleOnly', 'mixedTeam']} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} />
                        <SDGs inputId="sdgs" sdgs={inputData.sdg} saveData={this.saveData}/>
                        <TextArea inputId="sustainability" saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error}/>
                        <TextInput inputId="email" saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} autoComp="email"/>
                        <TextInput inputId="contactPerson" saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} autoComp="name"/>
                        <Password inputId="password" withRepeat={true} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} />
                        
                        <div className="illufield signup">
                            <div>
                                <Checkbox inputId="receiveNewsletter" saveData={this.saveData} />
                                <Checkbox inputId="contactViaEmail" saveData={this.saveData} />
                                <Checkbox inputId="confirmSignup" saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} />
                                <button type="submit">{this.props.intl.formatMessage({
                                    id: 'button.signup',
                                    defaultMessage: 'Sign up'
                                })}</button>
                            </div>
                            <div id="signup_woman" className="illustration">
                                <img src={signUpIllu} alt={this.props.intl.formatMessage({
                                    id: 'alt.signupIllu',
                                    defaultMessage: 'Illustration of woman with pen'
                                })} />
                            </div>
                        </div>
                        {this.state.error ? ErrorMessage : null}
                        {authError ? AuthErrorMessage : null}
                     </form>
                    : <form ref={this.signupForm} onSubmit={this.handleSubmit}>
                        <div>
                            <TextInput inputId="email" saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} autoComp="email"/>
                            <TextInput inputId="name" inputLabel="yourfullname" saveData={this.saveData} showError={this.state.error} autoComp="name"/>
                            <Password inputId="password" withRepeat={true} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} />
                            <Checkbox inputId="receiveNewsletter" saveData={this.saveData} />
                            <Checkbox inputId="confirmSignup" saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} />
                            <button type="submit">{this.props.intl.formatMessage({
                                id: 'button.signup',
                                defaultMessage: 'Sign up'
                            })}</button>
                        </div>
                        {this.state.error ? ErrorMessage : null}
                        {authError ? AuthErrorMessage : null}
                    </form>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        authError: state.auth.authError,
        registerState: state.auth.registerState
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        signUp: (newUser) => dispatch(signUp(newUser)),
        // fetchUserData: () => dispatch(fetchUserData())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    injectIntl
)(SignUpForm)