import React, {useState, useEffect, Component} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
// import { firestoreConnect } from 'react-redux-firebase'
import ListItem from '../listComponents/ListItem';

class FeaturedBusiness extends Component {

    

    render() {

        let business = this.props.featured;
        console.log(this.props.featured)

        return (
        <div className="featuredBusiness">
            <h3>{this.props.intl.formatMessage({
                id: 'message.featuredBusiness',
                defaultMessage: 'QUEEN OF THE MONTH'
            })}</h3>
            {this.props.featured ? 
                <ListItem business={this.props.featured} logo={this.props.featured.downloadURL}/>
            : null}
        </div>
    )}
}

// const FeaturedBusiness = (props) => {
//     const [featured, setFeatured] = useState(null);

//     useEffect(() => {
//       setFeatured(props.featured)
//     });
  
//     // console.log(props.businessId)
//     return (
//     <div className="featuredBusiness">
//         <h3>{props.intl.formatMessage({
//             id: 'message.featuredBusiness',
//             defaultMessage: 'QUEEN OF THE WEEK'
//         })}</h3>
//         {props.featured ? 
//             <ListItem business={featured} logo={featured.downloadURL}/>
//         : null}
//     </div>
//   );
// }

const mapStateToProps = (state) => {
    return {
        featured: state.business.featured
    }
}

export default compose(
    withRouter,
    injectIntl,
    // withFirebase,
    // firestoreConnect(props => [
    //     {
    //         collection: 'businesses',
    //         doc: props.businessId,
    //         storeAs: 'featured'
    //     }
    // ]),
    connect(mapStateToProps)
)(FeaturedBusiness);