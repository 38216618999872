import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import { firestoreConnect, withFirebase } from 'react-redux-firebase'
import { fetchFavourites } from '../../redux/actions/userActions'
import '../styles/profile.css'
import WebLinkIcon from '../profileComponents/WebLinkIcon';
import queen from '../../assets/queen_blink.svg';
import FavIcon from '../profileComponents/FavIcon';
import SafeSpaceIcon from '../profileComponents/SafeSpaceIcon';
import OpenIcon from '../profileComponents/OpenIcon';
import OpeningTimes from '../profileComponents/OpeningTimes';

class BusinessProfile extends Component {

    state = {
        business: {}
    }

    static propTypes = {
        // businessId: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired
    }

    componentDidMount() {
        if(this.props.favourites.length < 1){
            this.props.fetchFavourites();
        }
      }
    

    componentDidUpdate (prevProps, prevState) {
        if(prevProps.favourites !== this.props.favourites && this.props.favourites){
            this.setState({favourites: this.props.favourites})
        }
    }

    render() {
        let business = this.props.selectedBusiness ? this.props.selectedBusiness[0] : null;

        if (!business) {            

            return null;
        }

        let addressObj = business.address && business.address !== 'online' ? JSON.parse(business.address) : {};
    
        return(
        <nav className={`container businessProfile ${business.logoBackground || null} ${business.logoFill || null} logo-${business.downloadURL ? 'true' : 'false'}`}>
            {/* <div className="topSection"> */}
                <div className="imageContainer">
                    <div className="profileImage" style={{backgroundImage: `url(${business.downloadURL || queen})`}}>
                </div>
                </div>
                
            {/* </div> */}
            <div className="bottomSection">
                <div className="icons">
                    <div>
                        <SafeSpaceIcon safe={business.safeSpace} />
                        <OpenIcon openingTimes={business.openingTimes} />
                        <WebLinkIcon business={business} />
                    </div>
                    <FavIcon businessId={business.id} />
                </div>
                <div className="details">
                    <div className="address">
                        <div className="name">{business.companyName}</div>
                        <div className="street">{addressObj.location ? addressObj.location.address.street : null}&nbsp;{addressObj.location ? addressObj.location.address.houseNumber : null}</div>
                        <div className="postcode">{addressObj.location ? addressObj.location.address.postalCode : null}&nbsp;{addressObj.location ? addressObj.location.address.city : null}</div>
                    </div>
                    <OpeningTimes openingTimes={business.openingTimes} />
                </div>
                <div className="quote">
                    <span className="mark">&#8220;</span>
                    <span className="text">{business.shortDescription || business.description}</span>
                    <span className="mark">&#8221;</span>
                </div>
            </div>
        </nav>
        )
    }
}

// export default BusinessProfile

const mapStateToProps = (state) => {
    // console.log(state)
    return {
        auth: state.firebase.auth,
        selectedBusiness: state.firestore.ordered.selectedBusiness,
        favourites: state.user.favourites
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        fetchFavourites: () => dispatch(fetchFavourites())
    }
  }

export default compose(
    withRouter,
    withFirebase,
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        {
            collection: 'businesses',
            doc: props.match.params.uid,
            storeAs: 'selectedBusiness'
        }
    ]),
)(BusinessProfile);



// export default withRouter(connect(mapStateToProps)(BusinessProfile))