import React from 'react';
import {injectIntl} from 'react-intl'
import { compose } from 'redux'
import HerePlatform from '../mapComponents/HerePlatformHOC';

class InputAddress extends React.Component {
    state = {
        // data: null,
        error: null,
        info: null,
        address: null,
        value: "",
        online: false,
        edited: false
    }

    componentDidMount() {
        if(this.props.required && this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }

        if(this.props.inputData && !this.state.edited){
            // this.setState({value: this.props.inputData})
            this.setData()
        }
        
    }

    componentDidUpdate(prevProps) {
        if(prevProps.registerUpdate !== this.props.registerUpdate && this.props.registerUpdate && this.props.required){
            this.props.registerUpdate(this.props.inputId, false)
        }

        if(!prevProps.inputData && this.props.inputData && !this.state.edited){
            this.setData()
            // this.setState({value: this.props.inputData})
        }
        if(!prevProps.showError && this.props.showError){
            this.validate()
        } 
    }

    setData = () => {
        const data = this.props.inputData;

        if(data === 'online'){
            this.setState({online: true}, () => {
                if(this.props.registerUpdate){
                    this.props.registerUpdate(this.props.inputId, true)
                }
            })
        } else {
            this.setState({online: false})
            let addressObj = JSON.parse(data);
            
            // <div className="street">{addressObj.location ? addressObj.location.address.street : null}&nbsp;{addressObj.location ? addressObj.location.address.houseNumber : null}</div>
            //         <div className="postcode">{addressObj.location ? addressObj.location.address.postalCode : null}&nbsp;{addressObj.location ? addressObj.location.address.city : null}</div>
            let address = `${addressObj.location.address.street} ${addressObj.location.address.houseNumber}\n${addressObj.location.address.postalCode} ${addressObj.location.address.city} `
            this.setState({address: this.props.inputData, value: address}, () => {
                if(this.props.registerUpdate){
                    this.props.registerUpdate(this.props.inputId, true)
                }
            })
        }
    }

    // componentDidUpdate(prevProps) {
    //     if(prevProps.registerUpdate !== this.props.registerUpdate && this.props.registerUpdate && this.props.required){
    //         this.props.registerUpdate(this.props.inputId, false)
    //     }
    // }

    onError = (error) => {
        console.log(error)
    }

    geocode = (address) => {
        var geocoder = this.props.herePlatform.getGeocodingService(),
        geocodingParameters = {
            searchText: address,
            jsonattributes : 1
        };
    
        geocoder.geocode(
        geocodingParameters,
        this.onSuccess,
        this.onError
        );
    }

    onSuccess = (data) => {
        if(data.response.view[0] && data.response.view[0].result[0].matchLevel === "houseNumber"){
            let addressObj = data.response.view[0].result[0];
            let address = JSON.stringify(addressObj);

            if(data.response.view[0].result[0].relevance < 1){
                this.setState({info: 'info.address.typo'})
            } else {
                this.setState({info: null});
            }

            this.props.saveData(this.props.inputId, address);
            
            if(this.props.registerUpdate){
                this.props.registerUpdate(this.props.inputId, true)
            }
            
        } else {
            if(data.response.view[0] && data.response.view[0].result[0].matchLevel !== "houseNumber"){
                this.setState({error: 'error.address.detailMissing'});
            } else {
                this.setState({error: 'error.address.notFound'});
            }

            if(this.props.registerUpdate){
                this.props.registerUpdate(this.props.inputId, false)
            }
        }

        // needed when on blur functionality executes after checkbox event
        if(this.state.online){
            this.setState({info: null, error: null}, () => {
                if(this.props.registerUpdate){
                    this.props.registerUpdate(this.props.inputId, true)
                }
            });
        }
    }
    
    onError = (error) => {
        this.setState({error: error});
        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }
    }

    inputChanged = (e) => {
        this.setState({ value: e.target.value, 'error': null });
    }

    validate = () => {
        if(!this.state.online){
            let value = this.state.value;
            let err_msg = null;

            if(this.props.required && value.length < 1){
                err_msg = 'error.required';
            }

            if(err_msg){
                if(this.props.registerUpdate){
                    this.props.registerUpdate(this.props.inputId, false)
                }

                this.setState({ 'error': err_msg })
            } else {
                this.setState({ 'error': null });
                this.geocode(value);
            }
        } else {
            this.setState({ 'error': null });

            if(this.props.registerUpdate){
                this.props.registerUpdate(this.props.inputId, true)
            }
        }
    }

    checkboxChanged = (e) => {
        this.setState({ 'error': null });
        
        if(e.target.checked){
            this.setState({'online': true, 'error': null});
            this.props.saveData(this.props.inputId, 'online');
            if(this.props.registerUpdate){
                this.props.registerUpdate(this.props.inputId, true)
            }
        } else {
            this.setState({'online': false}, () => {
                this.validate()
            })
        }
    }

    render() {
        return (
            <div className={`inputContainer ${this.state.error ? 'hasError' : null} ${this.props.required ? 'required' : null}`}>
                <label className={this.props.inputType}>
                    <span className="labelText">{this.props.intl.formatMessage({
                        id: `label.${this.props.inputId}`,
                        defaultMessage: `label.${this.props.inputId}`
                    })}{this.props.required ? ' *' : null}</span>
                    <textarea className={this.state.online ? 'disabled' : null} id={this.props.inputId} onChange={this.inputChanged} onBlur={this.validate} value={this.state.value} />
                    <div>
                        <input type="checkbox" onChange={this.checkboxChanged} checked={this.state.online}/>
                        <label className="checkboxLabel">{this.props.intl.formatMessage({
                            id: "label.checkbox.address",
                            defaultMessage: 'Online business only'
                        })}</label>
                    </div>
                </label>
                <div className="errorMessage">{this.props.intl.formatMessage({
                    id: this.state.error || 'error',
                    defaultMessage: 'This field is not filled out correctly.'
                })}</div>
                {this.state.info ? 
                    <div className="errorMessage info">{this.props.intl.formatMessage({
                        id: this.state.info || 'info',
                        defaultMessage: 'There might be a problem with this field.'
                    })}</div>
                : null }
            </div>
        );
    }
}

export default compose(
    HerePlatform,
    injectIntl
)(InputAddress)
