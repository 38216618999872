import React from 'react'
import {injectIntl} from 'react-intl'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    PinterestIcon,
    EmailIcon
  } from 'react-share';

const ShareButtons = (props) => {
    const { shareUrl, title } = props;
    let subj = props.emailSubject || null;
    let bdy = props.emailBody || null;

    const emailSubject = subj ?
        props.intl.formatMessage({
            id: subj,
            defaultMessage: 'QUEEN OF THE NEIGHBORHOOD'
        })
        : "";

     const emailBody = bdy ?
        props.intl.formatMessage({
            id: bdy,
            defaultMessage: 'Hi, I came across QUEEN OF THE NEIGHBORHOOD, a platform for female entrepreneurs. Have a look:'
        })
        : "";

    return (
        <nav className="shareButtons">
            <div>{props.intl.formatMessage({
                id: 'message.share',
                defaultMessage: 'Recommend'
            })}</div>
            <FacebookShareButton url={shareUrl} quote={title} className="share-button">
                <FacebookIcon size={32} round/>
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} quote={title} className="share-button">
                <TwitterIcon size={32} round/>
            </TwitterShareButton>
            {props.linkImg ? 
            <PinterestShareButton url={String(window.location)} media={`https://app.quotn.co/${props.linkImg}`} className="share-button">
               <PinterestIcon size={32} round/>
            </PinterestShareButton>
            : null }
            <LinkedinShareButton url={shareUrl} quote={title} className="share-button">
                <LinkedinIcon size={32} round/>
            </LinkedinShareButton>
            <WhatsappShareButton url={shareUrl} quote={title} className="share-button">
                <WhatsappIcon size={32} round/>
            </WhatsappShareButton>
            {(props.emailSubject && props.emailBody) ? 
            <EmailShareButton url={shareUrl} quote={title} className="share-button" subject={emailSubject} body={emailBody}>
                <EmailIcon size={32} round/>
            </EmailShareButton>
            : null }
        </nav>
    )
}

export default injectIntl(ShareButtons);