import React from 'react';
import { withRouter } from 'react-router-dom'

const FAQ = () => {
    return(
        <div id="FAQ" className="infoContainer">
            <h1>WE THINK WE KNOW, WHAT YOU WANT TO KNOW. SO HERE YOU GO:</h1>
            <div className="faq">
                <h2>Does it cost money to list my business at QUOTN?</h2>
                <p>No. Listing your business is free. We are working on a feature package for premium memberships that will cost money though. Those premium accounts will be launched when we launch the new features.</p>
            </div>
            <div className="faq">
                <h2>How do you make money then? Are you selling my data?</h2>
                <p>No. Your data is save with us. We do sell advertising space to businesses within our community. In a nutshell, that is how we make money.</p>
            </div>
            <div className="faq">
                <h2>How do you pick the businesses on your platform?</h2>
                <p>Everyone can sign up. But we reserve the right to not publish listings, if we think they don’t fulfill our guidelines.</p>
            </div>
            <div className="faq">
                <h2>So what are those guidelines?</h2>
                <p>We are currently working on a handbook and a test you can run for yourself, to validate, whether you fulfill those guidelines or not. For now we like to keep it local, sustainable and independent. If that sounds like you, you should definitely sign up.</p>
            </div>
            <div className="faq">
                <h2>My business wasn’t listed. Why?</h2>
                <p>Something might have gone wrong with validating your e-mail address. Make sure that worked and maybe contact us about it. If everything worked out with the e-mail, we might have felt your business was not appropriate for our platform. In that case we will notify you at some point.</p>
            </div>
            <div className="faq">
                <h2>How can I take my business off the platform, if I don’t want it to be listed anymore?</h2>
                <p>Please contact us if you wish to take your business off our platform. We are working on enabling you to do this on your own via your settings.</p>
            </div>
            <div className="faq">
                <h2>When will the app be in the app store? Where can I download the app?</h2>
                <p>For now, we are “only” running a web app. That has several reasons, mainly because we want to make our boarding quick and comfortable for you, saving you the steps of installing an app from an app store! Please do save our app to your home screen in order to have that proper "app feeling".</p>
            </div>
            <h3>ANYTHING WE DIDN’T COVER? PLEASE DON’T HESITATE TO CONTACT US!</h3>
        </div>
    )
}

export default withRouter(FAQ);

