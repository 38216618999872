import React, {Component} from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import WelcomeMessage from '../startScreenComponents/WelcomeMessage';
import NewBusinesses from '../startScreenComponents/NewBusinesses';
import '../styles/startscreen.css'
import FeaturedBusiness from '../startScreenComponents/FeaturedBusiness';
import { fetchFeatured } from '../../redux/actions/businessActions'
// import { connect } from 'react-redux';
// import { compose } from 'redux'


export class StartScreen extends Component {

    componentDidMount() {
        this.props.fetchFeatured();
    }

    render() {
        // let business = this.props.settings && this.props.settings.publicSettings ? this.props.settings.publicSettings.featuredBusiness : null; 
        return(
            <div id="startScreen">
                <WelcomeMessage />
                <NewBusinesses />
                    <FeaturedBusiness />
                    {/* <FeaturedBusiness businessId={this.props.settings.publicSettings.featuredBusiness}/> */}
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    // console.log(state.firestore.data.appData)
    return{
        settings: state.firestore.data.appData
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        fetchFeatured: () => dispatch(fetchFeatured())
    }
  }

export default compose(
    // withRouter,
    // injectIntl,
    // withFirebase,
    connect(mapStateToProps, mapDispatchToProps),
    // firestoreConnect(() => [
    //     {
    //         collection: 'appData',
    //         doc: 'publicSettings'
    //     }
    // ])
)(StartScreen);


// export default Welcome;