import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

class RadioButtonGroup extends React.Component {
    state = {
        value: '',
        error: null,
        edited: false
        // selectedButton: null,
    }

    static getDerivedStateFromProps(props, state) {
        if (state.edited) {
          return {};
        }
        
        if(props.inputData && props.inputData.length > 0){
            return {value: props.inputData};
        }

        return {}
    }

    componentDidMount() {
        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.showError && this.props.showError){
            this.validate()
        } 
    }

    radioChanged = (e) => {
        if(!this.state.edited){ 
            this.setState({edited: true})
        }

        this.setState({value: e.target.value}, () => {
            this.validate()
        })
    }

    validate = () => {
        let valid = false;

        if(this.props.required && this.state.value.length < 1){
            this.setState({'error': 'error.required'})
        } else {
            valid = true;
            this.props.saveData(this.props.inputId, this.state.value);
        }

        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, valid)
        }
    }

    render() {
        return (
            <div>
                <div className={`inputContainer ${this.props.inputId} ${this.state.error ? 'hasError' : null} ${this.props.required ? 'required' : null}`}>
                    <label>
                        <span className="labelText">{this.props.intl.formatMessage({
                            id: `label.${this.props.inputLabel || this.props.inputId}`,
                            defaultMessage: `label.${this.props.inputId}`
                        })}{this.props.required ? ' *' : null}</span>
                        <div className="radio-group">
                            {this.props.options.map((item,i) =>
                                <label key={i} className="radio-div">
                                    {this.props.images ? <img src={this.props.images[i]} alt={item} /> : null}
                                    <input type="radio" name={this.props.inputId} value={item} onChange={e => this.radioChanged(e)} checked={this.state.value === item}/>
                                    <span className="radio-label">{this.props.intl.formatMessage({
                                        id: `option.${item}`,
                                        defaultMessage: item
                                    })}</span>
                                </label>
                            )}
                        </div>
                    </label>
                    <div className="errorMessage">{this.props.intl.formatMessage({
                        id: this.state.error || 'error',
                        defaultMessage: 'This field is not filled out correctly.'
                    })}</div>
                </div>
            </div>
        );
    }
}

RadioButtonGroup.propTypes = {
    inputId: PropTypes.string.isRequired,
    required: PropTypes.bool,
    options: PropTypes.array.isRequired,
    images: PropTypes.array
}

export default injectIntl(RadioButtonGroup);
