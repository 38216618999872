import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

class TextArea extends Component {
    state = {
        value: '',
        error: null,
        edited: false
    }

    static getDerivedStateFromProps(props, state) {
        if (state.edited) {
          return {};
        }
        return {value: props.inputData};
    }

    componentDidMount() {
        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.showError && this.props.showError){
            this.validate()
        } 
    }

    inputChanged = (e) => {
        if(!this.state.edited){ 
            this.setState({edited: true})
        }

        this.setState({value: e.target.value}, () => {
            this.validate();
        })
    }

    validate = () => {
        let err_msg = null;
        let valid = true;
        
        if(this.props.inputId === "description" && this.state.value.length > 99){
            err_msg = 'error.charLimitExceeded';
        }

        if(this.props.required && (!this.state.value || (this.state.value && this.state.value.length < 1))){
            err_msg = 'error.required';
            valid = false;
        } else {
            this.props.saveData(this.props.inputId, this.state.value)
        }

        this.setState({'error': err_msg}, () => {
            if(this.props.registerUpdate){
                this.props.registerUpdate(this.props.inputId, valid);
            }
        })
    }

    render() {
        return (
            <div className={`inputContainer ${this.props.inputId} ${this.state.error ? 'hasError' : null}`}>
                <label>
                    {this.props.hideLabel ? null :
                        <span className="labelText">{this.props.intl.formatMessage({
                            id: `label.${this.props.inputLabel || this.props.inputId}`,
                            defaultMessage: `label.${this.props.inputId}`
                        })}{this.props.required ? ' *' : null}</span>
                    }
                    <textarea id={this.props.inputId} onBlur={this.validate} onChange={this.inputChanged} maxLength={this.props.maxLength || 1000} value={this.state.value}/>
                </label>
                {this.state.error ?
                    <div className="errorMessage">{this.props.intl.formatMessage({
                        id: this.state.error || 'error',
                        defaultMessage: 'This field is not filled out correctly.'
                    })}</div>
                : null}
            </div>
        );
    }
}

TextArea.propTypes = {
    inputId: PropTypes.string.isRequired,
    required: PropTypes.bool
}


export default injectIntl(TextArea);
