import React, { Component } from 'react'
import { Switch, Route, Redirect} from 'react-router-dom'
import SignIn from './components/auth/SignIn';
// import SignUp from './components/auth/SignUp';
import SignUpScreen from './components/screens/SignupScreen';
// import SignUp from './components/auth/SignUp';
import SignUpForm from './components/auth/SignUpForm';
// import Welcome from './components/screens/Welcome';
import NavBar from './components/nav/NavBar';
import EmailActionHandler from './components/auth/EmailActionHandler';
import SettingsList from './components/screens/SettingsList';
import { withTracker } from './config/GoogleAnalytics';
import HomeScreen from './components/screens/HomeScreen';
import BusinessProfile from './components/screens/BusinessProfile';
import ForgotPassword from './components/auth/ForgotPassword';
import Prompt from './components/ui/Prompt';
import ChangeEmail from './components/settingsComponents/ChangeEmail';
import FAQ from './components/infoComponents/FAQ';
import './components/styles/info.css'
import './components/styles/auth.css'
import LoginRegisterScreen from './components/screens/LoginRegisterScreen';
import ProfileEditor from './components/settingsComponents/ProfileEditor';
import StartScreen from './components/screens/StartScreen';

class App extends Component {
  // componentDidMount() {
  //   this.checkPermissions()
  // }

  // async checkPermissions () {     
  //   await navigator.permissions.query({
  //     name: 'geolocation'
  //   }).then(function(result) {
  //       if (result.state == 'denied') {
  //           alert("Location access has been blocked for this app. Please update your preferences in the device settings in order to use location features in this app.")
  //       }
  //   }).catch((err) => {
  //     console.log(err)
  //   });
  // }

  render() {
    return (
      <div className={`app ${this.props.filtersVisible ? "no-scroll" : "scroll"}`}>
        <NavBar />
        <Switch>
          <Route exact path='/' component={withTracker(StartScreen)}/>
          <Route exact path='/businesses/map' render={(props) => <HomeScreen {...props} favList={false} mode='map'/>}/>
          <Route exact path='/businesses/list' render={(props) => <HomeScreen {...props} favList={false} mode='list'/>}/>
          <Route exact path='/businesses/favlist' render={(props) => <HomeScreen {...props} favList={true} />} />
          <Route path='/businesses/:uid' component={withTracker(BusinessProfile)}/>
          <Route exact path='/auth/register/' component={withTracker(SignUpScreen)}/>
          <Route exact path='/auth' component={withTracker(LoginRegisterScreen)}/>
          <Route path='/auth/login' component={withTracker(SignIn)}/>
          <Route path='/auth/register/:type' component={withTracker(SignUpForm)}/>
          <Route path='/auth/handlemail/' component={withTracker(EmailActionHandler)} />
          <Route exact path='/settings/' component={withTracker(SettingsList)} />
          <Route path='/settings/change-email' component={withTracker(ChangeEmail)} />
          <Route path='/settings/edit-profile' component={withTracker(ProfileEditor)}/>
          <Route path='/settings/faq' component={withTracker(FAQ)}/>
          <Route path='/forgot-password/' component={withTracker(ForgotPassword)} />
          {/*  Redirect old routes */}
          <Route exact path='/login' render={() => <Redirect to="/auth/login" />} />
          <Route exact path='/register' render={() => <Redirect to="/auth/register" />} />
          <Route exact path='/register/business' render={() => <Redirect to="/auth/register/business" />} />
          <Route exact path='/register/private' render={() => <Redirect to="/auth/register/private" />} />
        </Switch>
        <Prompt />
      </div>
    )
  }
}

export default App
