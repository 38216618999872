import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter, Link, Redirect } from 'react-router-dom'
import { compose } from 'redux'
import {injectIntl} from 'react-intl'
import {sendPasswordResetMail, signOut, resendVerificationMail} from '../../redux/actions/authActions'

import '../styles/settings.css'

class SettingsList extends Component {
    state = {
        'showError' : true,
        'goToEdit': false,
        'latestMessage': null
    }

    errorNoticed = () => {
        // alert('click')
        this.setState({'showError' : false})
    }

    render() {
        const { authError, auth, authMessage, editState, editError } = this.props;

        let editMessage = editState ? `profileEdit.${editState}` : null;
        let message = authMessage || editMessage;
        // let message = [this.state.latestMessage];

        // if (!auth.uid) {
        //     return (
        //         <Redirect to="/auth" />
        //     )
        // }

        return (
            <div id="settingsContainer">
                <div className="accountDetails"></div>
                {auth.uid ? 
                    <div className="settingsSection">
                        <h3>{this.props.intl.formatMessage({
                            id: 'account',
                            defaultMessage: 'Account'
                        })}</h3>
                        <div className="link" onClick={() => this.props.sendPasswordResetMail(auth.email)} >{this.props.intl.formatMessage({
                            id: 'settings.changePassword',
                            defaultMessage: 'Change Password'
                        })}</div>
                        <Link to="/settings/edit-profile" >{this.props.intl.formatMessage({
                            id: 'settings.editProfile',
                            defaultMessage: 'Edit Profile'
                        })}</Link>
                        <Link to="/settings/change-email" >{this.props.intl.formatMessage({
                            id: 'settings.changeEmail',
                            defaultMessage: 'Change E-mail Address'
                        })}</Link>
                        {this.props.auth.emailVerified ? null : 
                            <div className="link" onClick={() => this.props.resendVerificationMail()} >{this.props.intl.formatMessage({
                                id: 'settings.verifyEmail',
                                defaultMessage: 'Verify E-mail Address'
                            })}</div>
                        }
                        <div className="link" onClick={() => this.props.signOut()}>{this.props.intl.formatMessage({
                            id: 'settings.logout',
                            defaultMessage: 'Log Out'
                        })}</div>
                    </div>
                : null}
                <div className="settingsSection">
                    <h3>{this.props.intl.formatMessage({
                        id: 'support',
                        defaultMessage: 'Support'
                    })}</h3>
                    <a href="mailto:wazzup@quotn.co?subject=Contact via App">{this.props.intl.formatMessage({
                        id: 'contact',
                        defaultMessage: 'Contact'
                    })}</a>
                    <Link to="/settings/faq">{this.props.intl.formatMessage({
                        id: 'FAQ',
                        defaultMessage: 'FAQ'
                    })}</Link>
                </div>
                <div className="settingsSection">
                    <h3>{this.props.intl.formatMessage({
                        id: 'about',
                        defaultMessage: 'About'
                    })}</h3>
                    <a href="https://www.quotn.co/manifesto.html" target="_blank">{this.props.intl.formatMessage({
                        id: 'manifesto',
                        defaultMessage: 'Manifesto'
                    })}</a>
                    <a href="https://www.quotn.co/imprint.html" target="_blank">{this.props.intl.formatMessage({
                        id: 'imprint_legal',
                        defaultMessage: 'Imprint & Legal'
                    })}</a>
                    {/* <a href="https://www.quotn.co/legal.html" target="_blank">Legal</a> */}
                </div>
                {message ? 
                    <div className="messages">
                        {this.props.intl.formatMessage({
                            id: message,
                            defaultMessage: message
                        })}
                    </div>
                : null}
               
                {(authError || editError) && this.state.showError ? 
                    <div className="authErrorMessage">
                        <span>{authError || editError}</span>
                        <button onClick={() => this.errorNoticed()}>OK</button>
                    </div>
                : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth,
        registerState: state.auth.registerState,
        authError: state.auth.authError,
        authMessage: state.auth.authMessage,
        editState: state.user.editState,
        editError: state.user.editError
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        resendVerificationMail: () => dispatch(resendVerificationMail()),
        sendPasswordResetMail: (email) => dispatch(sendPasswordResetMail(email)),
        signOut: () => dispatch(signOut()),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    injectIntl
)(SettingsList)

// export default withRouter(connect(mapStateToProps)(SettingsList))