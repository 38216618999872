const initState = {
    userType: null,
    favourites: [],
    userData: {},
    userError: null,
    editError: null,
    editState: null
}

const userReducer = (state = initState, action) => {
    switch(action.type){
        case 'USERDATA_FETCH_PRIVATE_SUCCESS':
            return {
                ...state,
                userType: "private",
                userData: action.payload,
                userError: null,
                editState: null
            }
        case 'USERDATA_FETCH_BUSINESS_SUCCESS':
            return {
                ...state,
                userType: "business",
                userData: action.payload,
                userError: null,
                editState: null
            }
        case 'USERDATA_FETCH_ERROR':
            return {
                ...state,
                userError: action.error
            }
        case 'FAV_FETCH_SUCCESS':
            return {
                ...state,
                favourites: action.payload,
                userError: null
            }
        case 'FAV_CHANGED':
            return {
                ...state,
                favourites: action.payload,
                userError: null
            }
        case 'PROFILE_CHANGE_START':
            return {
                ...state,
                editState: 'start',
                editError: null
            }
        case 'PROFILE_CHANGE_SUCCESS':
            return {
                ...state,
                editState: 'success',
                editError: null
            }
        case 'PROFILE_CHANGE_ERROR':
            console.log(action.err.message)
            return {
                ...state,
                editState: 'error',
                editError: action.err
            }
        case 'RESET_EDIT_STATE':
            return {
                ...state,
                editState: null,
                editError: null
            }
        case 'RESET_USER':
            return {
                ...state,
                favourites: [],
                userError: null,
                editState: null,
                editError: null
            }
        default:
            return state;
    }
}

export default userReducer;