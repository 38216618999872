// import React from 'react';
// import scriptLoader from 'react-async-script-loader'
// import {app_id, app_code} from '../../config/hereConfig'
// import pin from '../../assets/pin.svg';

export const app_id = 'Xnwyny1mNR4FsrGdnlwZ';
export const app_code = 'b8pCGJb2HyemSyLOzniY5g';

// let new_script = (src) => {
//     return new Promise(function(resolve, reject){
//       var script = document.createElement('script');
//       script.src = src;
//       script.addEventListener('load', function () {
//         resolve();
//       });
//       script.addEventListener('error', function (e) {
//         reject(e);
//       });
//       document.body.appendChild(script);
//     })
// };
//   // Promise Interface can ensure load the script only once.
// let here_core = new_script('https://js.api.here.com/v3/3.0/mapsjs-core.js');
// let here_service = new_script('https://js.api.here.com/v3/3.0/mapsjs-service.js');
// let here_events = new_script('http://js.api.here.com/v3/3.0/mapsjs-mapevents.js');

// export const here_scripts = Promise.all([here_core, here_service, here_events]);

