import React, {Component} from 'react'
import {connect} from 'react-redux'
import update from 'immutability-helper'
import { fetchFavourites } from '../../redux/actions/userActions'
// import { saveDistances } from '../../redux/actions/businessActions'
import '../styles/list.css'
import pug from '../../assets/no_results.jpg'
import ListItem from './ListItem';
import Message from '../ui/Message';

class List extends Component {
  state = {
    businesses: [],
    favourites: [],
    distances: {},
    loadState: "loading"
  }

  componentDidMount() {
    this.props.fetchFavourites();

    if(this.props.businesses && this.props.businesses.length){
      this.setState({'businesses': this.props.businesses}, () => {
        this.setState({'loadState': 'loaded'})
      })
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.businesses && this.props.businesses !== this.state.businesses){
      this.setState({'businesses': this.props.businesses}, () => {
        if(this.props.businesses.length){
          this.setState({'loadState': 'loaded'})
        }
      })
    }

    if(this.props.distances && this.props.distances !== this.state.distances){
      this.setState({'distances': this.props.distances})
    }

    if(this.props.favourites && this.props.favourites !== this.state.favourites){
      this.setState({'favourites': this.props.favourites})
    }
  }

  compare = (a,b) => {
    if(this.state.distances[a.id] && this.state.distances[b.id]){
      if (this.state.distances[a.id] < this.state.distances[b.id])
        return -1;
      if (this.state.distances[a.id] > this.state.distances[b.id])
        return 1;
    }

    if(!this.state.distances[a.id] && this.state.distances[b.id]){
      return 1;
    }

    if(this.state.distances[a.id] && !this.state.distances[b.id]){
      return -1;
    }

    return 0;
  }

  render() {
      let businesses = !this.props.favMode ? this.state.businesses : this.state.businesses.filter(x => this.state.favourites.indexOf(x.id) > -1);
      let loadState = this.state.loadState;
      // console.log(this.state.favourites)

      return (
        <div className="listContainer">
         {businesses ? 
            businesses.sort(this.compare).map((business,index) => <ListItem key={index} business={business} distance={this.state.distances[business.id]} fav={this.props.favourites.indexOf(business.id) > -1}/>)
            : null}
          {loadState === 'loaded' && businesses.length < 1 && !this.props.favMode ? 
            <div className="nodata">
              <Message message="message.nodata" />
              <img src={pug} />
            </div>
          : null}
          {loadState === 'loaded' && businesses.length < 1 && this.props.favMode ? 
            <div className="nodata">
              <Message message="message.nodata.favs" />
              {/* <img src={pug} /> */}
            </div>
          : null}

          <div className="spacer"></div>

        {/* {this.props.promptOpen ? 
          <Prompt data={this.props.promptData}/>
          : null
        } */}
        </div>
      )
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return{
      distances: state.business.distances
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      fetchFavourites: () => dispatch(fetchFavourites()),
      // saveDistances: (distances) => dispatch(saveDistances(distances))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
