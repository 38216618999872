import React from 'react'

const addhttp = (url) => {
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
       url = "http://" + url;
    }
    return url;
}

const WebLinkIcon = (props) => {
    if(props.business.website){
        return (
            <a href={addhttp(props.business.website)} target="_blank" rel="noopener noreferrer" >
                <i className="flaticon-internet"></i>
            </a>
        )
    } else {
        return <div></div>
    }
}

export default WebLinkIcon;