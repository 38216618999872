import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

class TextInput extends Component {
    state = {
        value: '',
        error: null,
        edited: false
    }

   static getDerivedStateFromProps(props, state) {
        if (state.edited) {
          return {};
        }
        
        if(props.inputData && props.inputData.length > 0){
            return {value: props.inputData};
        }

        return {}
    }

    componentDidMount() {
        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.showError && this.props.showError){
            this.validate()
        }
    }

    // componentDidUpdate(prevProps) {
    //     if(prevProps.registerUpdate !== this.props.registerUpdate && this.props.registerUpdate){
    //         console.log("register from update")
    //         this.props.registerUpdate(this.props.inputId, false)
    //     }
    // }

    inputChanged = (e) => {
        this.setState({edited: true, value: e.target.value}, () => {
            this.validate();
        })
    }

    validate = () => {
        // console.log("vlidata")
        let err_msg = null;
        let valid = true;
        let value = this.state.value;

        let email_re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
        let url_re = /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/;

        switch(this.props.inputId){
            case "email":
                if(!email_re.test(value)){
                    err_msg = 'error.emailInvalid'
                }
                break;
            case "website":
                if(!url_re.test(value) || value.indexOf(" ") > -1){
                    err_msg = 'error.urlInvalid'
                }
                break;
            case "foundationYear":
                if(value.length < 4 || Number(value) > 2019 ||value.isNAN){
                    err_msg = 'error.foundationYear'
                }
                break;
            default:
                err_msg = null;
                break;
        }

        if(this.props.required && (!this.state.value || (this.state.value && this.state.value.length < 1))){
            err_msg = 'error.required';
            valid = false;
        }

        if(!this.props.required && value.length < 1){
            err_msg = null;
            valid = true;
        }

        this.setState({'error': err_msg}, () => {
            if(this.props.registerUpdate){
                this.props.registerUpdate(this.props.inputId, valid);
            }

            if(!err_msg){
                this.props.saveData(this.props.inputId, this.state.value)
            }
        })
    }

    render() {
        return (
            <div className={`inputContainer ${this.props.inputId} ${this.state.error ? 'hasError' : null}`}>
                <label>
                    {this.props.hideLabel ? null :
                        <span className="labelText">{this.props.intl.formatMessage({
                            id: `label.${this.props.inputLabel || this.props.inputId}`,
                            defaultMessage: `label.${this.props.inputId}`
                        })}{this.props.required ? ' *' : null}</span>
                    }
                    <input type={this.props.inputType || "text"} id={this.props.inputId} onBlur={this.validate} onChange={this.inputChanged} value={this.state.value} autoComplete={this.props.autoComp}/>
                </label>
                {this.state.error ?
                    <div className="errorMessage">{this.props.intl.formatMessage({
                        id: this.state.error || 'error',
                        defaultMessage: 'This field is not filled out correctly.'
                    })}</div>
                : null}
            </div>
        );
    }
}

TextInput.propTypes = {
    inputId: PropTypes.string.isRequired,
    required: PropTypes.bool
}


export default injectIntl(TextInput);
