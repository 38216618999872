const initState = {
    distances: null,
    featured: null,
    loadError: null
}

const businessReducer = (state = initState, action) => {
    switch(action.type){
        case 'UPDATE_DISTANCES':
            return {
                ...state,
                distances: action.value
            }
        case 'FETCH_FEATURED_SUCCESS':
        // console.log(action.payload)
            return {
                ...state,
                featured: action.payload
            }
        case 'FETCH_FEATURED_ERROR':
            return {
                ...state,
                loadError: action.error
            }
        default:
            return state;
    }
}

export default businessReducer;