
import React, { Component } from 'react'
import {app_id, app_code} from '../../config/herePlatform'
import postscribe from 'postscribe';

const HerePlatform = WrappedComponent => {
  
    return class HereHOC extends Component {
        state = {
            core: false,
            service: false,
            mapevents: false,
            ui: false
        }
        

        componentDidMount () {
            postscribe('#postscribe', '<script language="javascript" async="async" src="https://js.api.here.com/v3/3.0/mapsjs-core.js"></script>', {afterAsync: () => this.scriptLoaded("core")});
            postscribe('#postscribe', '<script language="javascript" async="async" src="https://js.api.here.com/v3/3.0/mapsjs-service.js"></script>', {afterAsync: () => this.scriptLoaded("service")});
            postscribe('#postscribe', '<script language="javascript" async="async" src="https://js.api.here.com/v3/3.0/mapsjs-mapevents.js"></script>', {afterAsync: () => this.scriptLoaded("mapevents")});
            postscribe('#postscribe', '<script language="javascript" async="async" src="https://js.api.here.com/v3/3.0/mapsjs-ui.js"></script>', {afterAsync: () => this.scriptLoaded("ui")});
            postscribe('#postscribe', '<script language="javascript" async="async" src="https://js.api.here.com/v3/3.0/mapsjs-clustering.js"></script>', {afterAsync: () => this.scriptLoaded("clustering")});
        }

        scriptLoaded = (part) => {
            this.setState({[part]: true}, () => {
                if(this.state.core && this.state.service && this.state.mapevents && this.state.ui && this.state.clustering){
                    this.initPlatform();
                }
            });
        }

        initPlatform = () => {
            const H = window.H;
    
            const platform = new H.service.Platform({
                'app_id': app_id,
                'app_code': app_code,
                'useHTTPS': true
            });

            this.setState({
                'herePlatform': platform,
                'here': H
            });

            this.getUserPosition();
        }

        async getUserPosition () {     
            await navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.setState({userPosition: position})
                },
                (error) => console.log(error)
            );
        }

        onError = (error) => {
            console.log(error)
        }

        render() {
            return (this.state.herePlatform && this.state.here) ? (
                <WrappedComponent {...this.props} herePlatform={this.state.herePlatform} here={this.state.here} userPosition={this.state.userPosition} calculateDistanceTo={this.calculateDistanceTo} />
            ) : (
                <div>
                    <h3>Loading</h3>
                </div>
            );
        }
    };
};

export default HerePlatform;