import React, {Component} from 'react'
import {connect} from 'react-redux'
import { compose } from 'redux'
import { withRouter, Redirect, Link } from 'react-router-dom'
import {signIn} from '../../redux/actions/authActions'
import InputElem from '../formComponents/InputElem'
import {injectIntl} from 'react-intl'

class SignIn extends Component {
    state = {
        email: '',
        password: ''
    }
    
    handleChange = (target) => {
        this.setState({
            [target.id]: target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state);
    }
    
    render() {
        const {auth, authError, emailHandlerState, emailHandlerMode, emailHandlerError} = this.props;

        if(auth.uid){
            return (
                <Redirect to="/settings" />
            )
        } else {

            return (
                <div className="container login">
                    <h2>Login</h2>
                    {(emailHandlerState !== 'default' && (authError || emailHandlerError)) ?
                        <div className="email-handler-message">
                            {/* {this.props.authMessage} */}
                            {/* <div className="state">{this.props.intl.formatMessage({
                                id: "message." + emailHandlerMode + "." + emailHandlerState,
                                defaultMessage: emailHandlerState
                            })}</div> */}
                        <div className="email-handler-error">{authError || emailHandlerError || null}</div>
                        </div>
                    : null }
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <InputElem inputType="email" inputId="email" inputChanged={this.handleChange} />
                            <InputElem inputType="password" inputId="password" inputChanged={this.handleChange} />
                            <Link to="/forgot-password">{this.props.intl.formatMessage({
                                id: "link.forgotPw",
                                defaultMessage: "forgot Password?"
                            })}</Link>
                            <button>Login</button>
                        </div>
                        {authError ? 
                            <div className="errorMessages">
                                <span class="error">{authError}</span>
                            </div>
                         : null}
                    </form>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth,
        authError: state.auth.authError,
        authMessage: state.auth.authMessage,
        emailHandlerMode: state.auth.emailHandlerMode,
        emailHandlerState: state.auth.emailHandlerState,
        emailHandlerError: state.auth.emailHandlerError
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        signIn: (creds) => dispatch(signIn(creds))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    injectIntl
)(SignIn)