import React from 'react'

const SafeSpaceIcon = (props) => {
    if(props.safe === 'yes'){
        return <i className="flaticon-lock"></i>
    } else {
        return null
    }
}

export default SafeSpaceIcon;