import React from 'react'
import {connect} from 'react-redux'
import pin from '../../assets/map-localization-white.svg';

const ToggleView = (props) => {
    // const links = auth.uid ? <SignedInLinks /> : <SignedOutLinks />;

    // const links = auth.emailVerified ? <SignedInLinks /> : null;
    
    // TODO: Update nav on prop change
    return (
        <div className="toggleView" onClick={() => props.dispatch({ type: 'TOGGLE_MAP_VIEW' })}>
            {/* <img src={pin} alt="change to map view"/> */}
            {props.mapView === false ? 
                <i>
                    <img alt="map" src={pin} />
                </i>
            : 
            <i className="flaticon-list"></i>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        mapView: state.nav.mapView
    }
  }

export default connect(mapStateToProps)(ToggleView)