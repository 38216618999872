import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

class Password extends Component {
    state = {
        value: '',
        repeatValue: '',
        error: null,
        repeatError: null
    }

    componentDidMount() {
        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.showError && this.props.showError){
            this.validate()
        } 
    }

    // componentDidUpdate(prevProps) {
    //     if(prevProps.registerUpdate !== this.props.registerUpdate && this.props.registerUpdate){
    //         console.log("register from update")
    //         this.props.registerUpdate(this.props.inputId, false)
    //     }
    // }

    inputChanged = (e) => {
        this.setState({value: e.target.value}, () => {
            if(this.state.error){
                this.validate();
            }
        })
    }

    repeatChanged = (e) => {
        this.setState({repeatValue: e.target.value}, () => {
            if(this.state.repeatError){
                this.validate();
            }
        })
    }

    validate = () => {
        let err_msg = null;
        let repeat_err = null;
        let valid = true;
        let repeat_valid = true;

        if(this.props.required && this.state.value.length < 8){
            err_msg = this.state.value.length > 1 ? 'error.passwordLength' : 'error.required';
            valid = false;
        } else if(this.state.value !== this.state.repeatValue) {
            repeat_err = 'error.passwordMatch';
            repeat_valid = false;
            err_msg = 'error.passwordMatch';
            valid = false;
        }

        this.setState({'error': err_msg, 'repeatError': repeat_err}, () => {
            if(this.props.registerUpdate){
                this.props.registerUpdate(this.props.inputId, valid);
            }
            
            if(!err_msg) {
                this.props.saveData(this.props.inputId, this.state.value, true)
            }
        })
    }

    render() {
        return (
            <div>
                <div className={`inputContainer password ${this.state.error ? 'hasError' : null}`}>
                    <label>
                        {this.props.hideLabel ? null :
                            <span className="labelText">{this.props.intl.formatMessage({
                                id: "label.password",
                                defaultMessage: "label.password"
                            })}{this.props.required ? ' *' : null}</span>
                        }
                        <input type="password" id={this.props.inputId} onBlur={this.validate} onChange={this.inputChanged} value={this.state.value} autoComplete={this.props.withRepeat ? "new-password" : "current-password"}/>
                    </label>
                    {this.state.error ?
                        <div className="errorMessage">{this.props.intl.formatMessage({
                            id: this.state.error || 'error',
                            defaultMessage: 'This field is not filled out correctly.'
                        })}</div>
                    : null}
                </div>
                {this.props.withRepeat ? 
                    <div className={`inputContainer pw_repeat ${this.state.repeatError ? 'hasError' : null}`}>
                        <label>
                            {this.props.hideLabel ? null :
                                <span className="labelText">{this.props.intl.formatMessage({
                                    id: "label.pw-repeat",
                                    defaultMessage: "label.pw-repeat"
                                })} *</span>
                            }
                            <input type="password" onBlur={this.validate} onChange={this.repeatChanged} value={this.state.repeatValue} autoComplete="new-password"/>
                        </label>
                        {this.state.repeatError ?
                            <div className="errorMessage">{this.props.intl.formatMessage({
                                id: this.state.repeatError || 'error',
                                defaultMessage: 'This field is not filled out correctly.'
                            })}</div>
                        : null}
                    </div>
                    : null }
            </div>
        );
    }
}

Password.propTypes = {
    inputId: PropTypes.string.isRequired,
    required: PropTypes.bool,
    withRepeat: PropTypes.bool,
}


export default injectIntl(Password);
