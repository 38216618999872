import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import illu1 from '../../assets/signup/choose.jpg';
import ShareButtons from '../nav/ShareButtons'

class SignupScreen extends Component {
    state = {
        'showError' : true,
    }

    errorNoticed = () => {
        // alert('click')
        this.setState({'showError' : false})
    }

    render() {
        const { authError } = this.props;

        return (
            <div>
                <div id="signup-intro" className="container">
                    <div className="headline">
                        <h2>Let's get you started</h2>
                        <div className="subline">Please choose between the two</div>
                    </div>
                    <div className="illustration">
                        <img src={illu1} alt="woman between buildings" />
                    </div>
                    <div className="select-mode">
                        {/* <button onClick={() => props.onStart('private')}>Private User</button>
                        <button onClick={() => props.onStart('business')}>Business</button> */}
                        <div className="button"><Link to='/auth/register/private'>Private User</Link></div>
                        <div className="button"><Link to='/auth/register/business'>Business</Link></div>
                    </div>
                    <ShareButtons shareUrl="https://app.quotn.co/register" title="QUOTN Registration" linkImg={illu1} emailSubject="email.recommend.registration.subject" emailBody="email.recommend.registration.body"/>
                </div>
               
                {authError && this.state.showError ? 
                    <div className="authErrorMessage">
                        <span>{authError}</span>
                        <button onClick={() => this.errorNoticed()}>OK</button>
                    </div>
                : null}
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        registerState: state.auth.registerState,
        authError: state.auth.authError,
    }
}

export default withRouter(connect(mapStateToProps)(SignupScreen))