import React from 'react'
import { injectIntl } from 'react-intl';

const MapMessage = (props) => {
    return (
        <div className="messageContainer">
            {props.intl.formatMessage({
                id: 'message.noLocations',
                defaultMessage: 'Cannot find any businesses in your area? Contact us with your suggestions or check out Berlin!'
            })}
            <div className="text-link" onClick={props.messageAction}>
                Berlin
            </div>
        </div>
    )
}

export default injectIntl(MapMessage)