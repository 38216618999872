import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withFirebase } from 'react-redux-firebase'
import {injectIntl} from 'react-intl'
import { compose } from 'redux'
import { withRouter, Link, Redirect } from 'react-router-dom'
import {signUp} from '../../redux/actions/authActions'
import {updateProfile, updateLogo, dispatchMessage} from '../../redux/actions/userActions'
// import { withFirebase } from 'react-redux-firebase'
import update from 'immutability-helper'
import '../styles/styles.css'
import inputData from '../../data/inputData.json'
import OpeningTimes from '../formComponents/OpeningTimes';
import InputAddress from '../formComponents/InputAddress';
import RadioButtonGroup from '../formComponents/RadioButtonGroup';
import Checkbox from '../formComponents/Checkbox';

// import addressIllu from '../../assets/signup/address.jpg';
// import clockIllu from '../../assets/signup/clock.jpg';
// import wwwIllu from '../../assets/signup/www.jpg';
// import signUpIllu from '../../assets/signup/signup_b.jpg';

import smallCompIllu from '../../assets/signup/small_comp.jpg';
import mediumCompIllu from '../../assets/signup/med_comp.jpg';
import largeCompIllu from '../../assets/signup/large_comp.jpg';
import TextInput from '../formComponents/TextInput';
import {fetchUserData} from '../../redux/actions/userActions'
import TextArea from '../formComponents/TextArea';
import Dropdown from '../formComponents/Dropdown';
import FileUpload from '../formComponents/FileUpload';
import SDGs from '../formComponents/SDGs';
// import NewsletterSignup from '../formComponents/NewsletterSignup';

class ProfileEditor extends Component {
    state = {
        signUpDetails: {},
        logo: '',
        // signUpData: {
        //     type : null,
        //     creds : {
        //         email: '',
        //         password: ''
        //     },
        //     details: {},
        //     logo: ''
        // },
        progress: 0,
        requiredInputs: {},
        missingFields: [],
        error: false,
        trySave: false,
        checkStatus: false,
    }

    componentDidMount () {
        this.props.fetchUserData()
        // this.saveLogoUrl()
        this.props.dispatchMessage('RESET_EDIT_STATE')
        this.props.dispatchMessage('RESET_AUTH_MESSAGE')

        if(this.props.userData && this.props.userData.logo && this.props.firebase){
            this.saveLogoUrl();
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if(this.props.authError && (!prevProps.authError || prevProps.authError !== this.props.authError)){
        //    alert(this.props.authError) 
        }

        // console.log(this.props.firebase.storage())

        if(prevProps.userData !== this.props.userData && this.props.userData.logo){
                this.saveLogoUrl();
            //TODO: store initial data ? maybe just update the changed ones
        }
    }

    saveLogoUrl = () => {
        // console.log(this.props.storage)
        let storage = this.props.firebase.storage();

        if(!this.props.userData.downloadURL){
            var storageRef = storage.ref(`userUploads/${this.props.uid}/${this.props.userData.logo}`);

            storageRef.getDownloadURL().then((url) => {
              this.saveData('downloadURL', url);
            }).catch(function(error) {
                console.log(error)
            });
        }
    }
    
    registerUpdateInput = (input, valid) => {
        let inputs = this.state.requiredInputs;
        inputs[input] = valid;

        this.setState({requiredInputs: inputs}, () => {
            this.calculateProgress();
        })
    }

    saveData = (id, value) => {
          this.setState(prevState => ({
            ...prevState,
            signUpDetails: {
                ...prevState.signUpDetails,
                [id]: value
            }
        }), () => {
            this.calculateProgress()
        })
    }

    saveFile = (id, file) => {
        let newState = update(this.state, {
            'logo': {$set: file},
            signUpDetails: {
                'logo': {$set: file.name}
            }
        })

        // this.props.updateLogo(file, this.props.userType)

        this.setState(newState, () => {
            this.calculateProgress();
        });
    }

    calculateProgress = () => {
        let progressNum = 0;
        let totalNum = 0;
        let missingFields = []

        for(const [input, valid] of Object.entries(this.state.requiredInputs)){
            totalNum ++;
            if(valid === true){
                progressNum ++;
            } else {
                missingFields.push(input)
            }
        }

        let progress = (progressNum / totalNum) * 100;
        this.setState({'progress': progress, 'missingFields': missingFields}, () => {

            let error = !!this.state.missingFields.length;

            if(this.state.trySave){
                this.setState({error: error}, () => {
                    if(!error){
                        this.submitForm();
                    }
                })
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        

        this.setState({'trySave': true}, () => {
            // if(!error){
                this.calculateProgress();
            // }
        });
    }

    submitForm = () => {
        this.setState({trySave: false, checkStatus: true}, () => {
            this.props.updateProfile(this.state.signUpDetails, this.props.userType, this.state.logo)
        })
    }

    render() {
        const {userData, userType, editError, editState} = this.props;
        const percentage = this.state.progress + "%";
        // const type = this.props.match.params.type || this.props.userType;
        const companySizeImages = [smallCompIllu, mediumCompIllu, largeCompIllu];

        // if(editState === 'start'){
        //     return <div className="message">START</div>
        // }

        if(editState === ('success' || 'error') && this.state.checkStatus){
            return <Redirect to="/settings/" />
        }

        return (
            <div className="container editProfile">
                <div className="closeBtn">
                    <Link to="/settings/">
                        <i className="material-icons">close</i>
                    </Link>
                </div>
                {/* <div className="progressBar">
                    <div className="progress" style={{width: percentage}}></div>
                </div> */}
                <h1>{this.props.intl.formatMessage({
                    id: 'editProfile.headline',
                    defaultMessage: 'Edit profile'
                })}</h1>
                <p>{this.props.intl.formatMessage({
                    id: 'editProfile.paragraph',
                    defaultMessage: `Hit 'save' when you are done editing`
                })}
                </p>
                {userType === 'business' ? 
                    <form ref={this.signupForm} onSubmit={this.handleSubmit}>
                        <TextInput inputId="companyName" inputData={userData.companyName} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} autoComp="org"/>
                        <InputAddress inputId="address" inputData={userData.address} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} autoComp="street-address"/>
                        <TextArea inputId="description" inputData={userData.description} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} maxLength="100" />
                        <TextInput inputId="industry" inputData={userData.industry} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error}/>
                        <OpeningTimes inputId="openingTimes" inputData={userData.openingTimes} saveData={this.saveData} registerUpdate={this.registerUpdateInput} required={true} showError={this.state.error}/>
                        <RadioButtonGroup inputId="safeSpace" inputData={userData.safeSpace} options={['yes', 'no']} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} />
                        <TextInput inputId="website" inputData={userData.website} saveData={this.saveData} required={false} autoComp="url"/>
                        <TextInput inputType="number" inputId="foundationYear" inputData={userData.foundationYear} saveData={this.saveData} required={false} />
                        <Dropdown inputId="legalForm" withSearch={false} inputOptions={inputData.legalForms} inputData={userData.legalForm} saveData={this.saveData} required={false} />
                        <RadioButtonGroup inputId="companySize" inputData={userData.companySize} options={inputData.companySizes} images={companySizeImages} saveData={this.saveData}/>
                        <div className={`logoGroup display-${this.state.signUpDetails.logoFill || userData.logoFill} bg-${this.state.signUpDetails.logoBackground || userData.logoBackground}`}> 
                            <FileUpload inputId="logo" saveData={this.saveFile} inputData={userData.downloadURL || this.state.signUpDetails.downloadURL} />
                            <div className="logoOptions">
                                <RadioButtonGroup inputId="logoFill" options={['fill', 'fit']} inputData={userData.logoFill} saveData={this.saveData} />
                                <RadioButtonGroup inputId="logoBackground" options={['black', 'white']} inputData={userData.logoBackground} saveData={this.saveData} />
                            </div>
                        </div> 
                        <RadioButtonGroup inputId="founderGender" options={['femaleOnly', 'mixedTeam']} inputData={userData.founderGender} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} />
                        <SDGs inputId="sdgs" sdgs={inputData.sdg} inputData={userData.sdgs} saveData={this.saveData} registerUpdate={this.registerUpdateInput} showError={this.state.error}/>
                        <TextArea inputId="sustainability" inputData={userData.sustainability} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error}/>
                        <TextInput inputId="contactPerson" inputData={userData.contactPerson} saveData={this.saveData} required={true} registerUpdate={this.registerUpdateInput} showError={this.state.error} autoComp="name"/>
                        <div> 
                            <Checkbox inputId="receiveNewsletter" inputData={userData.receiveNewsletter} saveData={this.saveData} />
                            <Checkbox inputId="contactViaEmail" inputData={userData.contactViaEmail} saveData={this.saveData} />
                            <button type="submit">{this.props.intl.formatMessage({
                                id: 'button.save',
                                defaultMessage: 'Save'
                            })}</button>
                        </div>
                        {this.state.error ?
                        <div className="errorMessages">
                            <div>
                                {this.props.intl.formatMessage({
                                    id: "error.completeForm",
                                    defaultMessage: "Please fill out the following fields:"
                                })}
                            </div>
                            {this.state.missingFields.map((title, index) => 
                                <div key={index}>
                                    {this.props.intl.formatMessage({
                                        id: `label.${title}`,
                                        defaultMessage: title
                                    })}
                            </div>)}
                        </div> : null}
                     </form>
                    : <form ref={this.signupForm} onSubmit={this.handleSubmit}>
                         <div>
                            <TextInput inputId="name" inputLabel="yourfullname" inputData={userData.name} saveData={this.saveData} showError={this.state.error} autoComp="name"/>
                            <Checkbox inputId="receiveNewsletter" inputData={userData.receiveNewsletter} saveData={this.saveData} />
                            <button type="submit">{this.props.intl.formatMessage({
                                id: 'button.save',
                                defaultMessage: 'Save'
                            })}</button>
                        </div>
                        {this.state.error ?
                        <div className="errorMessages">
                            <div>
                                {this.props.intl.formatMessage({
                                    id: "error.completeForm",
                                    defaultMessage: "Please fill out the following fields:"
                                })}
                            </div>
                            {this.state.missingFields.map((title, index) => 
                                <div key={index}>
                                    {this.props.intl.formatMessage({
                                        id: `label.${title}`,
                                        defaultMessage: title
                                    })}
                            </div>)}
                        </div> : null}
                    </form>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        authError: state.auth.authError,
        registerState: state.auth.registerState,
        uid: state.firebase.auth.uid,
        userType: state.user.userType,
        userData: state.user.userData,
        editState: state.user.editState,
        editError: state.user.editError
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        signUp: (newUser) => dispatch(signUp(newUser)),
        updateProfile: (data, type, logo) => dispatch(updateProfile(data, type, logo)),
        updateLogo: (logo, type) => dispatch(updateLogo(logo, type)),
        fetchUserData: () => dispatch(fetchUserData()),
        dispatchMessage: (message) => dispatch(dispatchMessage(message))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    injectIntl,
    withFirebase
    // firebaseConnect
)(ProfileEditor)