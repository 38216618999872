import React from 'react';
import {injectIntl} from 'react-intl'
import PropTypes from 'prop-types';
import update from 'immutability-helper'
import {formatTimes} from '../../utils/helperFunctions'

class Weekday extends React.Component {
    state = {
        openingTimes: this.props.inputData || [{'start': '', 'end': ''}],
        selected: this.props.selected,
        error: null
    }

    componentDidMount() {
        if(this.props.inputData && this.props.inputData.length > 0) {
            this.setState({openingTimes: this.props.inputData})
        }

        this.validate(true, true);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.registerUpdate !== this.props.registerUpdate && this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }

        if(prevProps.inputData !== this.props.inputData && this.props.inputData && this.props.inputData.length > 0){
            this.setState({openingTimes: this.props.inputData})
        }

        if(prevProps.selected !== this.props.selected ){
            this.setState({ 'selected': this.props.selected});
        }

        if(!prevProps.showError && this.props.showError){
            this.validate(true, false);
        } 
    }

    toggleSelected = () => {
        let selected = !this.state.selected;

        let timeObj = this.state.openingTimes.length > 0 ? this.state.openingTimes : [{'start': '', 'end': ''}];
        let times = this.state.selected ? timeObj : [];
        
        this.setState({selected: selected, openingTimes: timeObj}, () => {
            this.props.timesChanged(this.props.name, times)
            
            if(this.state.openingTimes.length > 0){
                this.validate(true, false)
            }
        });
    }

    changeTime = (index, value, time) => {
        console.log("changing")
        let formattedValue = formatTimes(value);

        let newTimes = update(this.state.openingTimes, {
            [index]: {
                [time]: {$set: value}
            }
        });

        if(formattedValue.length === 4){
            this.setState({openingTimes: newTimes}, () => {
                this.validate(false);
            });
        }
    } 

    addPeriod = () => {
        let timeObj = {'start': '', 'end': ''};

        let newState = update(this.state, {
            openingTimes: {$push: [timeObj]}
        });

        this.setState(newState);
    }

    removePeriod = (index) => {
        if(this.state.openingTimes.length > 1){
            this.setState({
                openingTimes: this.state.openingTimes.filter((_, i) => i !== index)
            }, () => {
                this.validate();
            });
        } else {
            this.toggleSelected();
        }
    }

    validate = (blur, reportOnly) => {
        let err_msg = null;
        let timeArray = this.state.openingTimes.sort((a,b) => a.start - b.start)
        // if(this.state.)
        timeArray.forEach((timeSlot, index) => {
            if(blur && (!this.state.openingTimes[index].end || !this.state.openingTimes[index].start)){
                err_msg = "error.timeMissing"
            } else if(this.state.openingTimes[index].end && this.state.openingTimes[index].start && (this.state.openingTimes[index].start >= this.state.openingTimes[index].end)){
                err_msg = "error.timeWrongOrder"
            } else if(timeArray[index + 1] && timeArray[index + 1].start < timeArray[index].end){
                err_msg = "error.overlappingTimes"
            } else if(this.state.openingTimes[index].end && this.state.openingTimes[index].start){
                this.props.timesChanged(this.props.name, this.state.openingTimes)
            }
        })

        if(!this.state.selected){
            err_msg = null;
        }

        if(reportOnly){
            this.props.reportError(this.props.name, err_msg ? true : false)
        } else {
            this.setState({'error': err_msg}, () => {
                this.props.reportError(this.props.name, err_msg ? true : false)
            })
        }
    }

    render() {
        return (
            <div className="weekdayContainer">
                <div className={"weekday " + (this.state.selected ? "selected" : null)} onClick = {() => this.toggleSelected()}>
                    <span>{this.props.intl.formatMessage({
                        id: `abbr.${this.props.name}`,
                        defaultMessage: this.props.name
                    })}</span>
                </div>
                {this.state.selected ?
                    <div className="periods">
                        {this.state.openingTimes.map((period, index) => 
                        <div key={index} className="period">
                             <input value={period.start || ' '} onChange={(e) => this.changeTime(index, e.target.value, "start")}  type="time"/>
                             <span>&nbsp;&ndash;&nbsp;</span>
                             <input value={period.end || ' '} onChange={(e) => this.changeTime(index, e.target.value, "end")} onBlur={() => this.validate(index, true) } type="time"/>
                             <div className="removePeriod" onClick={() => this.removePeriod(index)}>+</div>
                         </div>
                        )}
                        {this.state.openingTimes[this.state.openingTimes.length - 1].start && this.state.openingTimes[this.state.openingTimes.length - 1].end && !this.state.error ? 
                            <div className="addPeriod" onClick={() => this.addPeriod()}>+</div>
                        : null}
                        
                    </div>
                : null }
                {this.state.error ? 
                    <div className="errorMessage">{this.props.intl.formatMessage({
                        id: this.state.error || 'error',
                        defaultMessage: 'This field is not filled out correctly.'
                    })}</div>
                : null }
            </div>
        ) 
    }
}

Weekday.propTypes = {
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    withTimes: PropTypes.bool
}

export default injectIntl(Weekday);