import React from 'react';
import {injectIntl} from 'react-intl';
import signUpPrivate from '../../../assets/signup/signup.jpg';

class SignupMessage extends React.Component {
    render() {
        return (
            <div className="signup-message">
           
                <div>
                    <h2>Hey Queen!</h2>
                    <p>{this.props.intl.formatMessage({
                            id: "message.businessSignup",
                            defaultMessage: "Please sign up"
                        })}
                    </p>
                </div>
            
        </div>
        );
    }
}

export default injectIntl(SignupMessage);
