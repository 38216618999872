import React from 'react';
import { withRouter, Link } from 'react-router-dom'

const LoginRegisterScreen = (props) => {
    return(
        <div className="container loginRegister">
            <h2>What do you want to do?</h2>
            <div className="buttons">
                <div className="button"><Link to="/auth/login">Login</Link></div>
                <div className="button"><Link to="/auth/register">Register</Link></div>
            </div>
        </div>
    )
}

export default withRouter(LoginRegisterScreen);

