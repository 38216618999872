import React from 'react';
import {injectIntl} from 'react-intl'
import { IoIosArrowDown, IoIosClose } from 'react-icons/io'

class InputElem extends React.Component {
    state = {
        data: this.props.inputData || null,
        error: null,
        selectedOption: null,
        selectOpen: false,
        fileSelected: null,
        hasBlurred: false,
        value: null,
        tempFile: '',
    }

    componentDidMount() {
        // console.log(this.props.inputId)
        if(this.props.manageInput){
            this.props.manageInput(this.props.inputId, (this.props.required ? true : false), (this.props.required ? false : true), true)
        }
    }

    // update password repeat error message when password input is changed
    componentDidUpdate (prevProps, prevState, snapshot) {
        if(prevProps.pwCheck && prevProps.pwCheck !== this.props.pwCheck ){
            if(this.state.value === this.props.pwCheck){
                this.setState({ 'error': null});
            } else {
                this.setState({ 'error': 'Passwords do not match.'});
            }
        }
    }

    searchChanged = (evt) => {
        let query = evt.target.value;

        if(query.length){
          let result = this.props.inputData.filter(x => JSON.stringify(x).toLowerCase().includes(query.toLowerCase()));
          this.setState({ 'data': result })
        } else {
           this.setState({ 'data': this.props.inputData || null })
        }
    }

    clearSearch = () => {
        this.refs.search.value = "";
        this.setState({ 'data': this.props.inputData || null })
    }

    toggleDropdown = () => {
        this.setState({'selectOpen':!this.state.selectOpen})
    }

    optionClicked = (value) => {
        this.setState({ 'selectedOption': value });
        this.props.optionSelected(this.props.inputId, value);
        this.toggleDropdown();

        if(this.props.manageInput){
            this.props.manageInput(this.props.inputId, (this.props.required ? true : false), true, false)
        }
            
        if(this.withSearch){
            this.clearSearch();
        }
    }

    inputChanged = (e) => {
        if(this.props.inputType === "file" && e.target.files[0]){
            let file = e.target.files[0];

            this.setState({'fileSelected': file.name}, () => {
                this.renderImage(file)
            });

            this.props.inputChanged(e.target);

            if(this.props.manageInput){
                this.props.manageInput(this.props.inputId, (this.props.required ? true : false), true, false)
            }
            
         } 
         
         if (this.props.inputId === 'pw-repeat'){
            this.setState({'value': e.target.value});
         }

         if(this.state.hasBlurred === true){
             this.validate(e);
         }

         if(this.props.inputId === "description" && e.target.value.length > 99){
            this.setState({ 'error': 'error.charLimitExceeded' }) 
         } else if(this.props.inputId === "description" && e.target.value.length <= 99){
            this.setState({ 'error': null})
         }
    } 

    renderImage = (file) => {
        // let file = this.state.fileSelected;
        // generate a new FileReader object
        var reader = new FileReader();
      
        // inject an image with the src url
        reader.onload = (event) => {
            let tempFile = event.target.result
            this.setState({'tempFile': tempFile});
        }
      
        // when the file is read it triggers the onload event above.
        reader.readAsDataURL(file);
      }

    validate = (e, rtn) => {
        rtn = rtn || false;
        let value = e.target.value;
        let email_re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
        let url_re = /\b(?:(?:https?|ftp):\/\/|www\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/i;
        let err_msg = null;
        // alert(field);

        switch(this.props.inputId){
            case "email":
                if(!email_re.test(value)){
                    err_msg = 'error.emailInvalid'
                }
                break;
            case "website":
                if(!url_re.test(value)){
                    err_msg = 'error.urlInvalid'
                }
                break;
            case "password":
                if(value.length < 8){
                    err_msg = 'error.passwordLength'
                }
                break;
            case "pw-repeat":
                if(this.props.pwCheck !== value){
                    err_msg = 'error.passwordMatch'
                }
                break;
            case "foundationYear":
                if(value.length < 4 || Number(value) > 2019 || value.isNAN){
                    err_msg = 'error.foundationYear'
                }
                break;
            default:
                err_msg = null;
                break;
        }

        if(this.props.required && value.length < 1){
            err_msg = 'error.required';
        }

        if(!this.props.required && value.length < 1){
            err_msg = null;
        }

        if(rtn){
            let result = err_msg ? false : true;
            return result;
        } else {
            if(err_msg){
                this.setState({ 'error': err_msg })
            } else {
                this.setState({ 'error': null });
            }

            this.setState({ 'hasBlurred': true });
            this.props.inputChanged(e.target);
            
            if(this.props.manageInput){
                if(this.props.inputType === "checkbox"){
                    this.props.manageInput(this.props.inputId, (this.props.required ? true : false), false, true)
                } else {
                    this.props.manageInput(this.props.inputId, (this.props.required ? true : false), (err_msg ? false : true), (value.length ? false : true))
                }
            }
        }
    }

    render() {
        return (
            <div className={`inputContainer ${this.props.inputId} ${this.state.error ? 'hasError' : null} ${this.props.required ? 'required' : null}`}>
                <label className={this.props.inputType}>
                    {this.props.hideLabel ? null :
                        <span className="labelText">{this.props.intl.formatMessage({
                            id: `label.${this.props.inputLabel || this.props.inputId}`,
                            defaultMessage: `label.${this.props.inputId}`
                        })}{this.props.required ? ' *' : null}</span>
                    }
                    { this.props.inputType === "file" ? 
                        <div id="logo-img">
                            <div className="logo-container" style={{'backgroundImage': `url("${this.state.tempFile}")`}}>
                                {/* <img src={this.state.tempFile} alt="logo"/> */}
                            </div>
                            <span className="fileName">{this.state.fileSelected}</span>
                        </div>
                     : null
                    }
                    { this.props.inputType === "select" ? 
                        <div className={`dropdown ${this.state.selectOpen ? 'open' : 'closed'}`}>
                            <div onClick={this.toggleDropdown} className="dropbtn">
                                <div className="selectedOption">
                                    {this.state.selectedOption || this.props.intl.formatMessage({
                                        id: 'label.message.select',
                                        defaultMessage: 'Please select ...'
                                    })}
                                </div><i><IoIosArrowDown /></i>
                            </div>
                            <div id="dropMenu" >
                                <div className="collapsable">
                                    {this.props.withSearch ?
                                        <div className="search">
                                            <input ref="search" type="text" placeholder="Search" onChange={this.searchChanged} />
                                            <i onClick={this.clearSearch}><IoIosClose /></i>
                                        </div>
                                        : null
                                    }
                                    {this.state.data.map((term,index) => <div key={index} onClick={() => this.optionClicked(term)}>{term}</div>)}
                                </div>
                            </div>
                        </div>
                        : (this.props.inputType === "textarea" ? 
                            <textarea id={this.props.inputId} onBlur={this.validate} onChange={this.inputChanged} maxLength={this.props.maxLength || 1000}/>
                            :
                            <input type={this.props.inputType} id={this.props.inputId} onBlur={this.props.inputType !== 'file' ? this.validate : null} onChange={this.inputChanged}/>
                        )}
                </label>
                <div className="errorMessage">{this.props.intl.formatMessage({
                    id: this.state.error || 'error',
                    defaultMessage: 'This field is not filled out correctly.'
                })}</div>
            </div>
        );
    }
}

export default injectIntl(InputElem);