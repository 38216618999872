// export const checkInvite = (code) => {
//     return (dispatch, getState, {getFirebase, getFirestore}) => {
//         const firebase = getFirebase();
//         const firestore = getFirestore();
//         let storagePath = '';

//         firebase.auth().signInWithEmailAndPassword(
//             credentials.email,
//             credentials.password
//         ).then(() => {
//             dispatch({type: 'LOGIN_SUCCESS'});
//         }).catch((err) => {
//             dispatch({type: 'LOGIN_ERROR', err });
//         });
//     }
// }

export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            console.log('success');
            dispatch({type: 'LOGIN_SUCCESS'});
        }).catch((err) => {
            console.log(err);
            dispatch({type: 'LOGIN_ERROR', err });
        });
    }
}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({type: 'SIGNOUT_SUCCESS'})
            dispatch({type: 'RESET_USER'})
        });
    }
}

export const signUp = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        let userId;
        // const actionCodeSettings = {
        //     url: 'https://app.quotn.co/?email=' + firebase.auth().currentUser.email
        // }

        dispatch({type: 'SIGNUP_LOADING'})

        firebase.auth().createUserWithEmailAndPassword(
            newUser.creds.email,
            newUser.creds.password
        ).then((resp) => {
            userId = resp.user.uid;
            let storagePath = 'userUploads/' + userId + '/';
            
            if(newUser.logo) {
                return firebase.uploadFile(
                    storagePath,
                    newUser.logo
                )
            } else {
                return;
            }
        }).then(() => {
            if(newUser.logo){
                let storageRef = firebase.storage().ref(`userUploads/${userId}/${newUser.logo.name}`);
                return storageRef.getDownloadURL()
            } else {
                return;
            }
        }).then((url) => {
            if(newUser.logo && url){
                newUser.details.downloadURL = url;
            }

            newUser.details.createdAt = firestore.FieldValue.serverTimestamp()
            let collectionPath = (newUser.type === 'business' ? 'businesses' : 'users');
            return firestore.collection(collectionPath).doc(userId).set(newUser.details)
        }).then(() => { 
            var user = firebase.auth().currentUser;
            user.sendEmailVerification().then(function() {
                dispatch({type: 'SIGNUP_VER_EMAIL_SENT'})
            }).catch(function(err) {
                dispatch({type: 'SIGNUP_VER_EMAIL_ERROR', err })
            });
            return;
        }).then(() => {
            dispatch({type: 'SIGNUP_SUCCESS'})
        }).catch((err) => {
            dispatch({type: 'SIGNUP_ERROR', err });
        });
    }
}

export const verifyEmail = (actionCode, continueUrl) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().applyActionCode(actionCode).then(function(resp) {
            // Email address has been verified.
            dispatch({type: 'VERIFY_EMAIL'})
            // TODO: Display a confirmation message to the user.
            // You could also provide the user with a link back to the app.
        
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
        }).catch(function(err) {
            dispatch({type: 'VERIFY_EMAIL_ERROR', err });
            // Code is invalid or expired. Ask the user to verify their email address
            // again.
        });
    }
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
}

export const resendVerificationMail = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        dispatch({type: 'VER_EMAIL_LOADING'})
        
        if(firebase.auth().currentUser){
            let user = firebase.auth().currentUser;
            
            user.sendEmailVerification().then(function() {
                dispatch({type: 'VER_EMAIL_SENT'})
            }).catch(function(err) {
                console.log(err)
                dispatch({type: 'VER_EMAIL_ERROR', err })
            });
        }
    }
}

export const sendPasswordResetMail = (email) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        dispatch({type: 'PW_RESET_EMAIL_LOADING'})
        
        firebase.auth().sendPasswordResetEmail(email).then(function() {
            dispatch({type: 'PW_RESET_EMAIL_SENT'})

            // Password reset confirmation sent. Ask user to check their email.
        }).catch(function(err) {
            console.log(err)
            dispatch({type: 'PW_RESET_EMAIL_ERROR', err })
            // Error encountered while sending password reset code.
        });
    }
}


export const resetPassword = (actionCode) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        console.log("reset starting")

        // log user out when reset password action has started
        firebase.auth().signOut().then(() => {
            dispatch({type: 'SIGNOUT_SUCCESS'})
            dispatch({type: 'RESET_USER'})
        });
  
        // let accountEmail;
        // Verify the password reset code is valid.
        firebase.auth().verifyPasswordResetCode(actionCode).then(function(email) {
            // let accountEmail = email;
            console.log("reset loading")
            dispatch({
                type: 'PW_RESET_START',
                payload: {
                    'actionCode': actionCode,
                    'email': email
                }
            })
        }).catch(function(err) {
            console.log("reset error: " + err)
            dispatch({type: 'PW_RESET_ERROR', err })
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        });
    }
}

export const confirmPasswordReset = (actionCode, newPassword) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
            // Save the new password.
        firebase.auth().confirmPasswordReset(actionCode, newPassword).then(function(resp) {
            // Password reset has been confirmed and new password updated.
    
            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
            dispatch({type: 'PW_RESET_SUCCESS'})
    
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
        }).catch(function(err) {
            dispatch({type: 'PW_RESET_ERROR', err })
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
        });
    }
}

export const updateEmailAddress = (email) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.updateEmail(email).then(function() {
            console.log('updating')
            dispatch({type: 'UPDATE_EMAIL_SUCCESS'})

            let user = firebase.auth().currentUser;
            
            user.sendEmailVerification().then(function() {
                dispatch({type: 'VER_EMAIL_SENT'})

                firebase.auth().signOut().then(() => {
                    dispatch({type: 'SIGNOUT_SUCCESS'})
                    dispatch({type: 'RESET_USER'})
                });
            }).catch(function(err) {
                console.log(err)
                dispatch({type: 'VER_EMAIL_ERROR', err })
            });
        }).catch(function(err) {
            console.log(err)
            dispatch({type: 'UPDATE_EMAIL_ERROR', err})
        });
    }
}

export const recoverEmail = (actionCode) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        let restoredEmail;
        // Confirm the action code is valid.
        firebase.auth().checkActionCode(actionCode).then(function(info) {
        // Get the restored email address.
            restoredEmail = info['data']['email'];
        
            // Revert to the old email.
            return firebase.auth().applyActionCode(actionCode);
        }).then(function() {
        // Account email reverted to restoredEmail
            dispatch({type: 'RESTORE_EMAIL_SUCCESS'})
        // TODO: Display a confirmation message to the user.
    
            firebase.auth().signOut().then(() => {
                dispatch({type: 'SIGNOUT_SUCCESS'})
                dispatch({type: 'RESET_USER'})
            });
            
        }).catch(function(err) {
            dispatch({type: 'RESTORE_EMAIL_ERROR', err})
        });
    }
}



// export const uploadTempFile = (file) => {
//     return (dispatch, getState, {getFirebase, getFirestore}) => {
//         const firebase = getFirebase();
//         const firestore = getFirestore();
//         const storagePath = 'tempUploads/';
//         // const dbPath = 'users/' + getState().firebase.auth.uid + "/files"

//         return firebase.uploadFile(
//             storagePath,
//             file,
//             storagePath
//         )
//     }
// }