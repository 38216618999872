export const openPrompt = (data) => {
    return {
        type: 'OPEN_PROMPT',
        data // it will add key `value` with argument value. 
    };
}

export const filtersVisible = (visible) => {
    return {
        type: 'FILTERS_VISIBLE_CHANGED',
        visible // it will add key `value` with argument value. 
    };
}