import React from 'react';
import {injectIntl} from 'react-intl';
import { IoIosArrowDown, IoIosClose } from 'react-icons/io'
import PropTypes from 'prop-types';

class Dropdown extends React.Component {
    state = {
        value: '',
        error: null,
        selectOpen: false,
        edited: false,
        // selectedButton: null,
    }

    static getDerivedStateFromProps(props, state) {
        if (state.edited) {
          return {};
        }

        return {value: props.inputData};
    }

    componentDidMount() {
        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.showError && this.props.showError){
            this.validate()
        } 
    }

    toggleDropdown = () => {
        this.setState({'selectOpen':!this.state.selectOpen})
    }

    optionClicked = (value) => {
        if(!this.state.edited){ 
            this.setState({edited: true})
        }

        this.setState({ 'value': value });
        this.props.saveData(this.props.inputId, value);
        this.toggleDropdown();

        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, true)
        }
            
        if(this.withSearch){
            this.clearSearch();
        }
    }

    clearSearch = () => {
        this.refs.search.value = "";
        this.setState({ 'data': this.props.inputData || null })
    }

    render() {
        return (
            <div>
                <div className={`inputContainer ${this.props.inputId} ${this.state.error ? 'hasError' : null} ${this.props.required ? 'required' : null}`}>
                    <label>
                        <span className="labelText">{this.props.intl.formatMessage({
                            id: `label.${this.props.inputLabel || this.props.inputId}`,
                            defaultMessage: `label.${this.props.inputId}`
                        })}{this.props.required ? ' *' : null}</span>
                        <div className={`dropdown ${this.state.selectOpen ? 'open' : 'closed'}`}>
                            <div onClick={this.toggleDropdown} className="dropbtn">
                                <div className="selectedOption">
                                    {this.state.value || this.props.intl.formatMessage({
                                        id: 'label.message.select',
                                        defaultMessage: 'Please select ...'
                                    })}
                                </div><i><IoIosArrowDown /></i>
                            </div>
                            <div id="dropMenu" >
                                <div className="collapsable">
                                    {this.props.withSearch ?
                                        <div className="search">
                                            <input ref="search" type="text" placeholder="Search" onChange={this.searchChanged} />
                                            <i onClick={this.clearSearch}><IoIosClose /></i>
                                        </div>
                                        : null
                                    }
                                    {this.props.inputOptions.map((term,index) => <div key={index} onClick={() => this.optionClicked(term)}>{term}</div>)}
                                </div>
                            </div>
                        </div>
                    </label>
                    <div className="errorMessage">{this.props.intl.formatMessage({
                        id: this.state.error || 'error',
                        defaultMessage: 'This field is not filled out correctly.'
                    })}</div>
                </div>
            </div>
        );
    }
}

Dropdown.propTypes = {
    inputId: PropTypes.string.isRequired,
    required: PropTypes.bool,
    options: PropTypes.array
}

export default injectIntl(Dropdown);
