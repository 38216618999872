import React from 'react';
import {injectIntl} from 'react-intl'
import update from 'immutability-helper'
import Weekday from './Weekday'

class OpeningTimes extends React.Component {
    state = {
        openingTimes : {
            monday : {
                times: [{'start': '', 'end': ''}]
            },
            tuesday : {
                times: [{'start': '', 'end': ''}]
            },
            wednesday : {
                times: [{'start': '', 'end': ''}]
            },
            thursday : {
                times: [{'start': '', 'end': ''}]
            },
            friday : {
                times: [{'start': '', 'end': ''}]
            },
            saturday : {
                times: []
            },
            sunday : {
                times: []
            }
        },
        dayErrors : new Set(),
        alwaysOpen: false,
        error: null,
        edited: false
        // unsaved: true
    }

    // TODO: validate function
    componentDidMount() {
        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }

        if(this.props.inputData && !this.state.edited) {
            if(this.props.inputData === "alwaysOpen"){
                this.setState({alwaysOpen: true})
            } else {
                // console.log(this.props.inputData)
                let dataObj = JSON.parse(this.props.inputData)
                this.setState({openingTimes: dataObj})
            }
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.registerUpdate !== this.props.registerUpdate && this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }

        if(!prevProps.inputData && this.props.inputData && !this.state.edited){
            if(this.props.inputData === "alwaysOpen"){
                this.setState({alwaysOpen: true})
            } else {
                let dataObj = JSON.parse(this.props.inputData)
                this.setState({openingTimes: dataObj})
            }
        }

        if(!prevProps.showError && this.props.showError){
            this.validate()
        }
    }

    checkOpen = (day) => {
        if(this.state.openingTimes[day] && this.state.openingTimes[day].times && this.state.openingTimes[day].times.length > 0){
            return true;
        }

        return false;
    }

    timesChanged = (day, times) => {
        if(!this.state.edited){ 
            this.setState({edited: true})
        }

        let updatedTimes = update(this.state.openingTimes, {
            [day]: {
                times: {$set: times}
            }
        });

        this.setState({openingTimes: updatedTimes}, () => {
            if(this.props.registerUpdate) {
                this.props.registerUpdate("openingTimes", true);
            }
            this.validate();
        });
    }

    storeErrors = (day, error) => {
        let dayErrors = this.state.dayErrors;

        if(error){
            dayErrors.add(day)
        } else {
            dayErrors.delete(day);
        }

        this.setState({dayErrors: dayErrors})
    }

    toggleAlwaysOpen = (e) => {
        if(!this.state.edited){ 
            this.setState({edited: true})
        }

        let alwaysOpen = e.target.checked;

        this.setState({alwaysOpen: alwaysOpen}, () => {
            // if(alwaysOpen){
                this.validate();
            // }
        })
    }

    validate = () => {
        let valid = false;
        let data = JSON.stringify(this.state.openingTimes);

        if(this.state.alwaysOpen){
            valid = true;
            this.props.saveData("openingTimes", "alwaysOpen");
        } else if(this.state.dayErrors.size < 1){
            valid = true;
            this.props.saveData("openingTimes", data)
        }

        if(this.props.registerUpdate) {
            this.props.registerUpdate("openingTimes", valid);
        }
    }

    render() {
        return (
            <div className={`inputContainer times ${this.state.error ? 'hasError' : null}`}>

                <label className={this.props.inputType}>
                    <span className="labelText">{this.props.intl.formatMessage({
                        id: `label.${this.props.inputLabel || this.props.inputId}`,
                        defaultMessage: `label.${this.props.inputId}`
                    })}{this.props.required ? ' *' : null}</span>
                </label>

                {/* <div className="times"> */}
                    <div className="weekdays">
                        <input id="alwaysOpen" type="checkbox" checked={this.state.alwaysOpen} onChange={(e) => this.toggleAlwaysOpen(e)}/>
                        <label>{this.props.intl.formatMessage({
                            id: "label.alwaysOpen",
                            defaultMessage: "always open"
                        })}</label>
                        {this.state.alwaysOpen ? null : Object.keys(this.state.openingTimes).map((k, i) => {
                            // console.log(this.state.openingTimes[k].times)
                            return <Weekday key={i} name={k} withTimes={true} timesChanged={this.timesChanged} inputData={this.state.openingTimes[k].times} toggleOpen={this.toggleOpen} selected={this.checkOpen(k)} showError={this.props.showError} reportError={this.storeErrors}/>
                        }
                        )}
                    </div>
                {/* </div> */}
                <div className="errorMessage">{this.props.intl.formatMessage({
                    id: this.state.error || 'error',
                    defaultMessage: 'This field is not filled out correctly.'
                })}</div>
                {/* <div className="saveMessage">{this.state.unsaved ? 'unsaved' : 'saved'}</div> */}
            </div>
        );
    }
}

export default injectIntl(OpeningTimes);