import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

class Checkbox extends React.Component {
    state = {
        checked: false,
        error: null,
        edited: false
    }

   static getDerivedStateFromProps(props, state) {
        if(props.inputData && !state.edited){
            return {checked: props.inputData};
        }

        return {}
    }

    componentDidMount() {
        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.showError && this.props.showError){
            this.validate()
        } 
    }

    checkboxChanged = (e) => {
        if(!this.state.edited){ 
            this.setState({edited: true})
        }

        let checked = e.target.checked ? true : false;
        
        this.setState({checked: checked}, () => {
            this.validate()
        })
    }

    validate = () => {
        if(this.props.required && !this.state.checked){
            this.setState({error: "error.required"})
        } else {
            this.setState({error: null})
            this.props.saveData(this.props.inputId, this.state.checked)
        }

        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, this.state.checked)
        }
    }

    render() {
        return (
            <div>
                <div className={`inputContainer checkbox ${this.props.inputId} ${this.state.error ? 'hasError' : null} ${this.props.required ? 'required' : null}`}>
                    <p>{this.props.intl.formatMessage({
                            id: `text.${this.props.inputId}`,
                            defaultMessage: `text.${this.props.inputId}`
                        })}
                    </p>
                    <label>
                        <input type="checkbox" onChange={e => this.checkboxChanged(e)} checked={this.state.checked}/>
                        <span className="labelText">{this.props.intl.formatMessage({
                            id: `label.${this.props.inputLabel || this.props.inputId}`,
                            defaultMessage: `label.${this.props.inputId}`
                        })}{this.props.required ? ' *' : null}</span>
                    </label>
                    {this.state.error ?
                        <div className="errorMessage">{this.props.intl.formatMessage({
                            id: this.state.error || 'error',
                            defaultMessage: 'This field is not filled out correctly.'
                        })}</div>
                    : null}
                </div>
            </div>
        );
    }
}

Checkbox.propTypes = {
    inputId: PropTypes.string.isRequired,
    required: PropTypes.bool
}

export default injectIntl(Checkbox);
