export const saveDistances = (value) => {
    return {
        type: 'UPDATE_DISTANCES',
        value // it will add key `value` with argument value. 
    };
}

export const fetchFeatured = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        let settingsRef = firestore.collection('appData').doc('publicSettings');
        
        // get featured business id from settings doc in database 
        settingsRef.get()
        .then(doc => {
            if (doc.exists && doc.data()) {
                let featuredId = doc.data().featuredBusiness;
                
                let featuredRef = firestore.collection('businesses').doc(featuredId);
                
                featuredRef.get()
                .then(fdoc => {
                    if (fdoc.exists && fdoc.data()) {

                        let data = fdoc.data();
                        data.id = featuredId;

                        dispatch({
                            type: 'FETCH_FEATURED_SUCCESS',
                            payload: data
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    dispatch({
                        type: 'FETCH_FEATURED_ERROR',
                        error: err
                    });
                });
            }
        })
        .catch((err) => {
            console.log(err)
            dispatch({
                type: 'FETCH_FEATURED_ERROR',
                error: err
            });
        });
    }
}

// export const getBusinesses = () => {
//     return (dispatch, getState, {getFirebase, getFirestore}) => {

//         // const firebase = getFirebase();

//         let businesses = [];
//         const firestore = getFirestore();
        
//         firestore.collection('businesses').get()
//         .then(querySnapshot => {
//             querySnapshot.docs.forEach(doc => {
//                 businesses.push(doc.data());
//             })
//         })
//         .catch((err) => {
//             dispatch({type: 'FAV_FETCH_ERROR', err });
//         });
//     }
// }