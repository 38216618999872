import React from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import ListItem from '../listComponents/ListItem';

const NewBusinesses = (props) => {
  return (
    <div className="newBusinesses">
        <h3>{props.intl.formatMessage({
            id: 'message.newBusinesses',
            defaultMessage: 'Our latest new businesses'
        })}</h3>
        <div className="businesses grid">
        {props.newBusinesses ? 
            props.newBusinesses.map((business,index) => 
            <div key={index}>
                <ListItem  business={business} logo={business.downloadURL}/>
            </div>)
        : null}
        </div>
    </div>
  );
}

const mapStateToProps = (state) => {
    return{
        newBusinesses: state.firestore.ordered.newBusinesses
    }
  }

export default compose(
    withRouter,
    injectIntl,
    connect(mapStateToProps),
    firestoreConnect([
        {
          collection: 'businesses',
          orderBy: [
            ['createdAt', 'desc']
          ],
          where: [
            ['addOnMap', '==', true]
          ],
          limit: 2,
          storeAs: 'newBusinesses'
      }
    ]) 
)(NewBusinesses)