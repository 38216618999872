import React, {Component} from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {injectIntl} from 'react-intl'
import { compose } from 'redux'
import queen from '../../assets/queen_blink.svg';
import FavIcon from '../profileComponents/FavIcon';
import SafeSpaceIcon from '../profileComponents/SafeSpaceIcon';
import OpenIcon from '../profileComponents/OpenIcon';

class ListItem extends Component {

    truncateString = (str, len) => {
        // let trimmedString = string.length > length ? string.substring(0, length - 3) + "..." : string.substring(0, length);
        // return trimmedString;
        if (str.length > len){
            let newLength;
            let append = " ...";  //Optional: append a string to str after truncating. Defaults to an empty string if no value is given
            
            str.length+append.length > len ? newLength = len-append.length : newLength = str.length; // if the length of original string and the appended string is greater than the max length, we need to truncate, otherwise, use the original string
            
            let tempString = str.substring(0, newLength);  //cut the string at the new length
            tempString = tempString.replace(/\s+\S*$/, ""); //find the last space that appears before the substringed text
            
            if (append.length > 0)
            {
                    tempString = tempString + append;
            }
            return tempString;
        } else {
            return str;
        }
    }

    render() {
        const { business } = this.props;
        let bgClass = business.logoBackground || '';
        let logoClass = business.logoFill || '';

        const onlineOnlyMessage = this.props.intl.formatMessage({
            id: "message.onlineOnly",
            defaultMessage: "online only"
        })

        let distance;
        let unit = this.props.distance > 1000 ? "km" : "m";

        if(this.props.distance){
            distance = this.props.distance > 1000 ? (this.props.distance / 1000).toFixed(1) : this.props.distance;
        }

        // let distString = distance + unit;

        if(business.companyName){
            return (
                <div className={`listItem ${bgClass} ${logoClass} logo-${business.downloadURL ? 'true' : 'false'}`}>
                    <Link to={`/businesses/${business.id}`}>
                        <div className="inner" style={{backgroundImage: `url(${business.downloadURL || queen})`}}></div>
                    </Link>
                    <h2>{this.truncateString(business.companyName, 50)}</h2>
                    <div className="icons">
                        <span className="distance">{distance ? (distance + unit) : (business.address === 'online' ? onlineOnlyMessage : '') }</span> 
                        <div>
                            <SafeSpaceIcon safe={business.safeSpace}/>
                            <OpenIcon openingTimes={business.openingTimes} />
                            <FavIcon businessId={business.id}/>
                        </div>
                    </div>
                    {/* <img src={props.logo} /> */}
                </div>
            )
        } else {
            return null;
        }
    }
}

export default compose(
    withRouter,
    injectIntl
)(ListItem)