import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {compose} from 'redux'
import {injectIntl} from 'react-intl'

class Prompt extends Component {

    state = {
        redirect: false,
        linkTo: null
    }

    buttonClicked = () => {
        if(this.props.promptData.linkTo){
            this.props.dispatch({ type: 'CLOSE_PROMPT' })
            this.props.history.push("/auth")

            // this.setState({linkTo: this.props.promptData.linkTo, redirect: true}, () => {
            //     this.props.dispatch({ type: 'CLOSE_PROMPT' })
            // })
        } else {
            //close prompt
        }
    }

    render(){
        const headline = this.props.promptData.headline || "prompt.default.headline";
        const content = this.props.promptData.content || "prompt.default.content";
        const buttonText = this.props.promptData.buttonText || "prompt.default.buttonText";
        // if(this.state.redirect){
        //     return(
        //         <Redirect to={this.state.linkTo} />
        //     )
        // }

        return (
            <div className={`promptContainer ${this.props.promptOpen ? "open" : "closed"}`}>
                <h2 className="promptHeadline">
                    {this.props.intl.formatMessage({
                        id: headline,
                        defaultMessage: headline
                    })}
                </h2>
                <div className="promptContent">
                    {this.props.intl.formatMessage({
                        id: content,
                        defaultMessage: content
                    })}
                </div>
                <div className="buttons">
                    {this.props.promptData.linkTo ? 
                        <div className="small-btn" onClick={() => this.buttonClicked()}>
                            {this.props.intl.formatMessage({
                                id: buttonText,
                                defaultMessage: buttonText
                            })}
                        </div>
                    : null}
                    <div className="small-btn" onClick={() => this.props.dispatch({ type: 'CLOSE_PROMPT' })}>
                            {this.props.intl.formatMessage({
                                id: "button.close",
                                defaultMessage: "close"
                            })}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state.firebase);
    return{
        promptOpen: state.nav.promptOpen,
        promptData: state.nav.promptData
    }
}

export default compose(
    connect(mapStateToProps),
    injectIntl,
    withRouter
)(Prompt)