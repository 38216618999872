import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
// import { GeoCollectionReference, GeoFirestore, GeoQuery, GeoQuerySnapshot } from 'geofirestore';

const fbConfig = {
    apiKey: "AIzaSyAfppDD06hUIs01hNdsedLl9JOaOwYjQoM",
    authDomain: "quotn-1535099598135.firebaseapp.com",
    databaseURL: "https://quotn-1535099598135.firebaseio.com",
    projectId: "quotn-1535099598135",
    storageBucket: "quotn-1535099598135.appspot.com",
    messagingSenderId: "95668661007"
}
  
firebase.initializeApp(fbConfig);
// firebase.firestore().settings({ timestampsInSnapshots: true })

// Create a Firestore reference
// const firestore = firebase.firestore();

// Create a GeoFirestore reference
// const geofirestore = new GeoFirestore(firestore);

// Create a GeoCollection reference
// const geoCollection = new GeoCollectionReference;
// const geoCollection = geofirestore.collection('locations');

// Create a GeoQuery based on a location
// query = geocollection.near({ center: new firebase.firestore.GeoPoint(40.7589, -73.9851), radius: 1000 });

// Get query (as Promise)
// query.get().then((value: GeoQuerySnapshot) => {
//   console.log(value.docs); // All docs returned by GeoQuery
// });

export default firebase;