import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

const sdg_imgs = require.context('../../assets/sdg/', true);

class SDGs extends React.Component {
    state = {
        value: null,
        error: null,
        edited: false,
        sdgSet: new Set()
        // selectedButton: null,
    }

    static getDerivedStateFromProps(props, state) {
        if (state.edited) {
          return {};
        }
        
        if(props.inputData && props.inputData.length > 0){
            let derivedSet = new Set(JSON.parse(props.inputData))
            return {value: props.inputData, sdgSet: derivedSet};
        }

        return {}
    }

    componentDidMount() {
        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, false)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.showError && this.props.showError){
            this.validate()
        } 
    }

    toggleSDG = (sdg) => {
        let sdgSet = this.state.sdgSet;

        if(sdgSet.has(sdg)){
            sdgSet.delete(sdg);
        } else {
            sdgSet.add(sdg)
        }

        let newValue = JSON.stringify(Array.from(sdgSet))

        this.setState({edited: true, value: newValue, sdgSet: sdgSet}, () => {
            this.props.saveData(this.props.inputId, this.state.value);
        })
    }

    // maybe don't validate at all in order not to make people select something even if they are not sustainable at all
    validate = () => {
        let valid = false;

        if(this.props.required && this.state.value.size < 1){
            this.setState({'error': 'error.required'})
        } else {
            valid = true;
            this.props.saveData(this.props.inputId, this.state.value);
        }

        if(this.props.registerUpdate){
            this.props.registerUpdate(this.props.inputId, valid)
        }
    }

    render() {
        return (
            <div>
                <div className={`inputContainer ${this.props.inputId} ${this.state.error ? 'hasError' : null} ${this.props.required ? 'required' : null}`}>
                    <label>
                        <span className="labelText">{this.props.intl.formatMessage({
                            id: "label.sdgs",
                            defaultMessage: "Sustainable Development Goals"
                        })}{this.props.required ? ' *' : null}</span>
                        <p>{this.props.intl.formatMessage({
                            id: "text.sdgs",
                            defaultMessage: "My business implements measures to work towards these sustainable development goals (by the UN)."
                        })}
                        </p>
                        <div className="sdg-grid">
                            {this.props.sdgs.map((item,i) =>
                                <label key={i} onClick={() => this.toggleSDG(item)} className={this.state.sdgSet.has(item) ? 'on' : 'off'}>
                                    <img src={sdg_imgs(`./E_SDG_Icons-${i + 1}.jpg`)} />
                                    {/* <input type="radio" name={this.props.inputId} value={item} onChange={e => this.radioChanged(e)} checked={this.state.value === item}/> */}
                                    <span>{this.props.intl.formatMessage({
                                        id: `sdg.${item}`,
                                        defaultMessage: item
                                    })}</span>
                                </label>
                            )}
                        </div>
                    </label>
                    <div className="errorMessage">{this.props.intl.formatMessage({
                        id: this.state.error || 'error',
                        defaultMessage: 'This field is not filled out correctly.'
                    })}</div>
                </div>
            </div>
        );
    }
}

// RadioButtonGroup.propTypes = {
//     inputId: PropTypes.string.isRequired,
//     required: PropTypes.bool,
//     options: PropTypes.array.isRequired,
//     images: PropTypes.array
// }

export default injectIntl(SDGs);
