import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import {confirmPasswordReset} from '../../redux/actions/authActions'
import InputElem from '../formComponents/InputElem'

class ResetPassword extends Component {
    state = {
        email: '',
        password: '',
        'pw-repeat': ''
    }
    
    handleChange = (target) => {
        this.setState({
            [target.id]: target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.password === this.state['pw-repeat']){
            // const {authError, registerState, emailHandlerData, emailHandlerError, emailHandlerMode, emailHandlerState} = this.props;
            this.props.confirmPasswordReset(this.props.emailHandlerData.actionCode, this.state.password);
        } else {
            alert("Passwords do not match")
        }
    }

    checkPasswords(repeat) {
        if(repeat === this.state.password){
            return true;
        }

        return false;
    }

    render() {
        const {emailHandlerData} = this.props;

        // if(pwResetState === 'error'){
        //     return (
        //         <Redirect to="/settings" />
        //     )
        // } else {

            return (
                <div className="container">
                    <div className="reset-info">Reset Password for: {emailHandlerData.email}</div>
                    <form id="reset-pw" onSubmit={this.handleSubmit}>
                        <InputElem inputType="password" inputId="password" inputChanged={this.handleChange} required={true} />
                        <InputElem inputType="password" inputId="pw-repeat" pwCheck={this.state.password} isPwOk={this.checkPasswords.bind(this)} inputChanged={this.handleChange} required={true}/>
                        <button>OK</button>
                        <div>
                            {/* {authError ? <span>{authError}</span> : null} */}
                        </div>
                    </form>
                </div>
            )
        // }
    }
}

const mapStateToProps = (state) => {
    return{
        emailHandlerData: state.auth.emailHandlerData
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        confirmPasswordReset: (code, pw) => dispatch(confirmPasswordReset(code, pw))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword))