import React, {Component} from 'react'
import {connect} from 'react-redux'
import {injectIntl} from 'react-intl'
import { compose } from 'redux'
import { Link, withRouter, Redirect } from 'react-router-dom'
import queryString from 'query-string'
import {verifyEmail, resetPassword, recoverEmail} from '../../redux/actions/authActions'
import ResetPassword from './ResetPassword';
// import InputElem from '../formComponents/InputElem'

class EmailActionHandler extends Component {
    state = {
        message: "verifying your email address"
    }

    // self = this;

    componentDidMount () {
        const { mode, oobCode, continueUrl } = queryString.parse(this.props.location.search);

  // Handle the user management action.
        switch (mode) {
            case 'resetPassword':
            // Display reset password handler and UI.
                this.props.resetPassword(oobCode, continueUrl);
                break;
            case 'recoverEmail':
            // Display email recovery handler and UI.
                this.props.recoverEmail(oobCode);
                break;
            case 'verifyEmail':
            // Display email verification handler and UI.
                this.props.verifyEmail(oobCode, continueUrl);
                break;
            default:
            // Error: invalid mode.
        }
        
    }

    render() {
        const { emailHandlerError, emailHandlerMode, emailHandlerState, userType} = this.props;
        console.log("mode/state: " + emailHandlerMode + " " + emailHandlerState)
        // let message = registerState === "verified" ? "Your E-Mail address has been verified!" : (authError || "Verifying. Please wait a moment.");

        if (emailHandlerMode === 'reset_pw'){
            if(emailHandlerState === 'start') {
                return (
                    <ResetPassword />
                )
            } else if (emailHandlerState === 'success') {
                return(
                    // <div className="container verifyMessage">
                    //     <h3>{this.props.intl.formatMessage({
                    //             id: 'message.pwReset.headline',
                    //             defaultMessage: 'Password has been reset.'
                    //         })}
                    //     </h3>
                    //     <p>{this.props.intl.formatMessage({
                    //             id: 'message.pwReset.paragraph',
                    //             defaultMessage: 'You can now log in with your new password.'
                    //         })}
                    //     </p>
                    // </div>
                    
                    <Redirect to="/auth/login"/>
                )
            } else if (emailHandlerState === 'error') {
                return(
                    <div className="container verifyMessage">
                        <h3>{this.props.intl.formatMessage({
                                id: 'error.pwReset',
                                defaultMessage: 'There has been an error resetting your password. Please contact us via dev@quotn.co'
                            })}
                        </h3>
                        <div>
                            Details: {emailHandlerError}
                        </div>
                    </div>
                )
            }
        }

        if (emailHandlerMode === 'verify_email') {
            if (emailHandlerState === 'success') {
                return (
                    <div className="container verifyMessage">
                        <h3>{this.props.intl.formatMessage({
                                id: 'message.verified.headline',
                                defaultMessage: 'Your E-Mail-Address has been verified.'
                            })}
                        </h3>
                        {userType === "business" ? 
                        <p>{this.props.intl.formatMessage({
                                id: 'message.verified.body',
                                defaultMessage: 'We will check your company and list you on our app shortly.'
                            })}
                        </p> : null }
                    </div>
                )
            } else if (emailHandlerState === 'error') {
                return (
                    <div className="container verifyMessage">
                        <h3>{this.props.intl.formatMessage({
                                id: 'error.verify',
                                defaultMessage: 'There has been an error sending your verification email. Please log in to your dashboard to resend the mail.'
                            })}
                        </h3>
                        <div>
                            Details: {emailHandlerError}
                        </div>
                        <Link to="/auth/login">Log In</Link>
                    </div>
                )
            }
        }

        if (emailHandlerMode === 'restore_email') {
            if (emailHandlerState === 'success') {
                return(
                    <div className="container verifyMessage">
                        <h3>{this.props.intl.formatMessage({
                                id: 'message.restoreEmail',
                                defaultMessage: 'Email address has been restored.'
                            })}
                        </h3>
                    </div>
                )
            } else if (emailHandlerState === 'error') {
                return(
                    <div className="container verifyMessage">
                        <h3>{this.props.intl.formatMessage({
                                id: 'error.restoreEmail',
                                defaultMessage: 'There has been an error restoring your email. Please contact us via dev@quotn.co.'
                            })}
                        </h3>
                        <div>
                            Details: {emailHandlerError}
                        </div>
                    </div>
                )
            }
        }
        
        // if(registerState === 'verified'){
        //     return (
        //         <div className="container verifyMessage">
        //             <h3>{this.props.intl.formatMessage({
        //                     id: 'message.verified.headline',
        //                     defaultMessage: 'Congrats you are amongst the first 500 to sign up!'
        //                 })}
        //             </h3>
        //             <p>{this.props.intl.formatMessage({
        //                     id: 'message.verified.paragraph',
        //                     defaultMessage: 'We will inform you about the launch of our app and the special surprise we have in store for you asap.'
        //                 })}
        //             </p>
        //             {/* <p>We will inform you about the launch of our app and the special surprise we have in store for you asap.</p> */}
        //             <img src={verifiedIllu} alt={this.props.intl.formatMessage({
        //                 id: 'alt.verifiedIllu',
        //                 defaultMessage: 'Woman doing high five gesture'
        //             })}/>
        //         </div>
        //     )
        // } else if (registerState === 'verifying') {
        //     return (
        //         <div className="container">
        //             <h3 className="verifyMessage">{authError || this.props.intl.formatMessage({
        //                 id: 'message.verifying',
        //                 defaultMessage: 'Verifying. Please wait a moment!'
        //             })}</h3>
        //         </div>
        //     )
        // }  else {
        //     return(
        //         null
        //         // <div className="container">
        //         //     <h3 className="verifyMessage">{authError || this.props.intl.formatMessage({
        //         //         id: 'message.actionPage.default',
        //         //         defaultMessage: 'You have come to our secret action page. Waiting for your action to load...'
        //         //     })}</h3>
        //         // </div>
        //     )
        // }

        return null
    }
}


const mapStateToProps = (state) => {
    // console.log(state.firebase);
    return{
        emailHandlerMode: state.auth.emailHandlerMode,
        emailHandlerState: state.auth.emailHandlerState,
        emailHandlerError: state.auth.emailHandlerError,
        userType: state.user.userType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        verifyEmail: (actionCode, continueUrl) => dispatch(verifyEmail(actionCode, continueUrl)),
        resetPassword: (actionCode, continueUrl) => dispatch(resetPassword(actionCode, continueUrl)),
        recoverEmail: (actionCode) => dispatch(recoverEmail(actionCode))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    injectIntl
)(EmailActionHandler)

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailActionHandler))