import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter, Link, Redirect } from 'react-router-dom'
import { compose } from 'redux'
import {injectIntl} from 'react-intl'
import InputElem from '../formComponents/InputElem';
import {updateEmailAddress} from '../../redux/actions/authActions'


export class ChangeEmail extends Component {
    state = {
        email: ""
    }
    
    handleChange = (target) => {
        this.setState({
            [target.id]: target.value
        })
    }

    changeEmailAddress = () => {
        this.props.updateEmailAddress(this.state.email);
    }
    
    render() {
        if(this.props.authMessage === "updateEmail.success"){
            return(
                <Redirect to="/settings" />
            )
        }

        return(
            <div className="singleSetting">
                <h3>{this.props.intl.formatMessage({
                    id: "setting.changeEmail",
                    defaultMessage: "Change E-Mail Address"
                })}</h3>
                <div>{this.props.auth.email}</div>
                <InputElem inputType="email" inputId="email" hideLabel={true} inputChanged={this.handleChange} required={true}/>
                <div className="small-btn" onClick={this.changeEmailAddress}>OK</div>
                {this.props.emailHandlerError ? 
                    <div className="errorMessage">{this.props.emailHandlerError}
                        {/* <h3>{this.props.intl.formatMessage({
                            id: "error." + this.props.emailHandlerError.code,
                            defaultMessage: this.props.emailHandlerError
                        })}</h3> */}
                    </div>
                : null}
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth,
        authMessage: state.auth.authMessage,
        authError: state.auth.authError,
        emailHandlerError: state.auth.emailHandlerError
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        updateEmailAddress: (email) => dispatch(updateEmailAddress(email))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    injectIntl
)(ChangeEmail)