import React, {Component} from 'react'
import {injectIntl} from 'react-intl'
import industryData from '../../data/industries.json'
import '../styles/ui.css'

class FilterSearchOptions extends Component {

    state = {
        activeFilters: new Set(),
        open: false,
        safespace: false,
        onlineOnly: false,
        industries: [],
        expanded: false,
        sliderActive: false,
        searchValue: '',
        sliderValue: 5
    }

    componentDidMount() {
        let industryArr = [];
    
        for (let industry in industryData) {
          industryArr.push(industry);
        }

        this.setState({"industries": industryArr});
    }

    toggleFilters = () => {
        let state = !this.state.visible;
        this.setState({'visible': state}, () => {
            this.props.filtersAreVisible(this.state.visible)
        })
    }

    toggleSlider = () => {
        let state = !this.state.sliderActive;
        this.setState({sliderActive: state}, () => {
            if(this.state.sliderActive){
                this.props.setFilter('distanceRange', this.state.sliderValue)
            } else {
                this.props.setFilter('distanceRange', 0)
            }
        })
    }

    search = (evt) => {
        let searchTerm = evt.target.value;

        this.setState({searchValue: searchTerm}, () => {
            this.props.setFilter('searchTerm', searchTerm)
        })
    }
    
    filterBusinesses = (evt) => {
        let filter = evt.target.value;
        let checked = evt.target.checked;
        let updatedFilters = this.state.activeFilters;

        if(checked){
            updatedFilters.add(filter)
        } else {
            updatedFilters.delete(filter);
        }
        
        this.setState({activeFilters: updatedFilters}, () => {
            // console.log(this.state.activeFilters)
            this.props.setFilter('activeFilters', this.state.activeFilters)
        })
    }
 
    radiusChanged = (e) => {
        this.setState({sliderValue: e.target.value}, () => {
            this.props.setFilter('distanceRange', this.state.sliderValue)
        })
    }

    toggleOption = (option) => {
        let state = !this.state[option];
        this.setState({[option]: state}, () => {
            this.props.setFilter([option], state)
        })
    }

    resetAll = () => {
        // alert("reset")
        // let updatedFilters = this.state.activeFilters.clear();
        this.setState({
            activeFilters: new Set(),
            sliderValue: 0, searchValue: '',
            open: false,
            safespace: false,
            onlineOnly: false,
            sliderActive: false
        }, () => {
            this.props.resetAll()
        })
    }
        
    
    // TODO: Update nav on prop change
    render(){
        return (
            <div className="filterSearchContainer">
                <div className="searchBar">
                    <div className="search">
                        <input type="text" value={this.state.searchValue} onChange={(e) => this.search(e)} placeholder="Type in a name, business type or industry" />
                    </div>
                    <div className="toggleFilters" onClick={this.toggleFilters}>
                        <i className="flaticon-setup"></i>
                    </div>
                </div>
                <div className={`filterOptions ${this.state.visible ? "visible" : "hidden"}`}>
                    
                    <div className="industryContainer">
                        <h3>{this.props.intl.formatMessage({
                            id: 'industry.industries',
                            defaultMessage: 'industries'
                        })}</h3>
                        <div className="selectIndustries">
                            {this.state.industries.map((filter,index) =>
                                <label key={index} className="checkboxLabel">
                                    <input type="checkbox" checked={this.state.activeFilters.has(filter)} onChange={e => this.filterBusinesses(e)} value={filter}/>
                                    <span>{this.props.intl.formatMessage({
                                        id: "industry." + filter,
                                        defaultMessage: filter
                                    })}</span>
                                </label>
                            )}
                        </div>
                    </div>
                    <div className="showOnly">
                        <h3>{this.props.intl.formatMessage({
                            id: 'ui.showOnly',
                            defaultMessage: 'show only'
                        })}</h3>
                        <div className="clickableOptions">
                            <div className={this.state.open ? 'active' : null} onClick={() => this.toggleOption("open")}>
                                <div className="inner">
                                <i className="flaticon-hour"></i>
                                    <span>{this.props.intl.formatMessage({
                                        id: 'ui.openBusinesses',
                                        defaultMessage: 'Open Businesses'
                                    })}</span>
                                </div>
                            </div>
                            <div className={this.state.safespace ? 'active' : null} onClick={() => this.toggleOption("safespace")}>
                                <div className="inner">
                                    <i className="flaticon-lock"></i>
                                    <span>Safe Spaces</span>
                                </div>
                            </div>
                            <div className={this.state.onlineOnly ? 'active' : null} onClick={() => this.toggleOption("onlineOnly")}>
                                <div className="inner">  
                                    <i className="flaticon-website"></i>
                                    <span>Online only</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.showRadius ? 
                        <div>
                            <h3>Radius</h3>
                            <div className={`sliderContainer ${this.state.sliderActive ? "active" : "disabled"}`}>
                                <input type="checkbox" onChange={e => this.toggleSlider(e)} checked={this.state.sliderActive} />
                                <input type="range" min="1" onChange={(e) => this.radiusChanged(e)} max="20" value={this.state.sliderValue} className="slider" id="distance" />
                                <label>{this.state.sliderValue}km</label>
                            </div>
                        </div>
                    : null}
                    <div className="buttons">
                        <div className="small-btn" onClick={() => this.resetAll()}>RESET</div>
                        <div className="small-btn" onClick={this.toggleFilters}>OK</div>
                    </div>
                </div>
            </div>
        )
    }
}


export default injectIntl(FilterSearchOptions)

// export default connect(mapStateToProps)(FilterSearchOptions)